import { getUniversalHelpers } from "../../helpers";
import moment from "moment";
import { MOMENT_DATE_TIME_FORMAT } from "../../constants";

export function openOrgEvents__client__getRecentOrgEvents(p: { orgId: string; type: "camp" | "tryout" }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return h.OpenOrgEvent.query({
    where: [
      { orgId: ["==", p.orgId] },
      { type: ["==", p.type] },
      { signupAvailabilityStartDatetime: [">=", moment().subtract(120, "days").format(MOMENT_DATE_TIME_FORMAT)] }
    ]
  }).then(a => a.docs);
}
