import { OpenOrgEvent, OpenOrgEventSession, OrgTeamTag, OrgTeamTagId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { diff } from "deep-diff";
import _, { update } from "lodash";

import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { ObjectKeys, getEndOfFinalOpenOrgEventSession } from "../../utils";
import { BatchTask } from "@ollie-sports/firebase";

export async function openOrgEvents__server__editOpenOrgEvent(p: {
  eventWithUpdates: Omit<OpenOrgEvent, "id">;
  eventId: string;
  selfAccountId: string;
}) {
  const { appOllieFirestoreV2: h } = getServerHelpers();

  const prevEventProm = h.OpenOrgEvent.getDoc(p.eventId);

  if (!p.eventWithUpdates.orgId || !(await h.Org.getDoc(p.eventWithUpdates.orgId))?.accounts[p.selfAccountId]?.exists) {
    throw new Error("Unauthorized access!");
  }

  const prevEvent = await prevEventProm;

  if (!prevEvent || prevEvent.id !== p.eventId) {
    throw new Error("Unable to change id of OpenOrgEvent!");
  }

  let newOrgTeamTagId: OrgTeamTagId | undefined;
  let isRemovingTeamTag = false;
  const updateDoc = _.pickBy(p.eventWithUpdates, (v, k) => !!diff(v, (prevEvent as any)[k]));

  if (updateDoc.orgTeamTagId) {
    newOrgTeamTagId = updateDoc.orgTeamTagId as string;
  }

  const fieldsToDelete = ObjectKeys(
    _.pickBy(prevEvent, (v, k) => {
      return !!diff(v, (p.eventWithUpdates as any)[k]);
    })
  ).filter(key => updateDoc[key] === undefined || updateDoc[key] === null) as string[];

  fieldsToDelete.forEach(field => {
    if (field !== "id") {
      updateDoc[field] = h._MagicDeleteValue;
    }
  });

  if (!!prevEvent.orgTeamTagId && !p.eventWithUpdates.orgTeamTagId) {
    isRemovingTeamTag = true;
  }

  const sessions = (updateDoc.sessions ?? prevEvent.sessions ?? []) as any as OpenOrgEventSession[];

  const endOfFinalSession = getEndOfFinalOpenOrgEventSession(sessions);
  updateDoc.derived = { endOfFinalSession };

  const batchTasks: BatchTask[] = [];

  if (ObjectKeys(updateDoc).length) {
    batchTasks.push(await h.OpenOrgEvent.updateShallow({ id: prevEvent.id, doc: updateDoc }, { returnBatchTask: true }));
    if (newOrgTeamTagId || isRemovingTeamTag) {
      const registrations = (
        await h.OpenOrgEventRegistration.query({ where: [{ openOrgEventId: ["==", p.eventId] }], limit: 5000 })
      ).docs;
      for (let i = 0; i < registrations.length; i++) {
        const registration = registrations[i];
        if (newOrgTeamTagId) {
          batchTasks.push(
            await h.OpenOrgEventRegistration.update(
              {
                id: registration.id,
                doc: {
                  tryoutInfo: {
                    sessionSelection: {
                      orgTeamTagId: newOrgTeamTagId
                    }
                  }
                }
              },
              { returnBatchTask: true }
            )
          );
        } else if (isRemovingTeamTag) {
          batchTasks.push(
            await h.OpenOrgEventRegistration.update(
              {
                id: registration.id,
                doc: {
                  tryoutInfo: {
                    sessionSelection: {
                      orgTeamTagId: h._MagicDeleteValue
                    }
                  }
                }
              },
              { returnBatchTask: true }
            )
          );
        }
      }
    }

    await Promise.all(
      _.chunk(batchTasks, 500).map(async chunk => {
        return await h._BatchRunner.executeBatch(chunk);
      })
    );
  }
}

openOrgEvents__server__editOpenOrgEvent.auth = (req: any) => {
  return validateTokenAndEnsureSelfAccountIdMatches(req);
};
