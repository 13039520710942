import { OpenOrgEvent, OrgId } from "@ollie-sports/models";
import moment from "moment";
import { MOMENT_DATE_TIME_FORMAT } from "../../constants";
import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function openOrgEvents__client__getOpenOrgEventSubscriptionBySlug(p: { slug: string; orgId: OrgId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftQuerySubToBifrostSub(
    h.OpenOrgEvent.querySubscription({
      where: [{ slug: ["==", p.slug], orgId: ["==", p.orgId] }],
      limit: 1
    })
  );
}
