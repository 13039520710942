import { getUniversalHelpers } from "../../helpers";

const IMAGE_DIRECTORY = "open_org_event/";

export async function openOrgEvents__client__uploadPlayerPhoto(p: { name: string; file: any }) {
  const { app } = getUniversalHelpers();
  return app
    .storage()
    .ref()
    .child(IMAGE_DIRECTORY + Date.now() + p.name)
    .put(p.file);
}

// i18n certified - complete
