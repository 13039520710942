import {
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Theme,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import firebase from "firebase";
import {
  calculateFeesForPayment,
  COLORS,
  compute,
  formatMoneyValue,
  getAllBirthYearAndGenderCombinations,
  getCostAfterDiscount,
  getDiscountedAmount,
  getTodayPaymentDetails,
  MOMENT_DATE_FORMAT,
  PricingInfo,
  StripePricingInfo
} from "@ollie-sports/core";
import { dateFormatters, getCurrentLocale, translate } from "@ollie-sports/i18n";
import {
  Coupon,
  OpenOrgEventRegistrationQuestion,
  GENDERS,
  OpenOrgEvent,
  OpenOrgEventRegistration,
  OpenOrgEventSession,
  Org,
  OrgSettings,
  OrgTeamTag,
  OpenOrgEventRegistrationAnswer,
  OpenOrgEventRegistrationQuestionType,
  OrgCoupon,
  OrgCouponType,
  OrgCouponUseType
} from "@ollie-sports/models";
import _ from "lodash";
import moment from "moment";
import React, { RefObject, useCallback, useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import { View, Image, StyleSheet, TouchableOpacity } from "react-native-web";
import { useLocation, useParams } from "react-router-dom";
import { CenteredLoader } from "../../components/CenteredLoader";
import { ColoredHeader } from "../../components/ColoredHeader";
import { ShadowView } from "../../components/ShadowView";
import { StyledText } from "../../components/StyledText";
import { getBifrost } from "../../services/bifrost.service";
import { Check, ChevronDown as ArrowDownIcon, ChevronUp as ArrowUpIcon, Delete } from "react-feather";
import { AddCircle } from "@material-ui/icons";
import { Expando } from "../../components/Expando";
import {
  PrettyKeyboardDatePicker,
  PrettySelect,
  PrettyInput,
  PrettyCheckbox,
  PrettyRadioGroup,
  PrettyCheckboxGroup,
  Form,
  FormRef
} from "../../components/Form";
import { usePersistentImmutableState, usePersistentSessionImmutableState } from "../../utils/usePersistentImmutableState";
import { prettyGender } from "../../utils/genderUtils";
import { downsampleAndCropImage } from "../../utils/image-tools";
import { maskNumericText } from "../../utils/maskText";
import { escapeHtmlAndWrapLinks } from "../../utils/parseText";
import { ensureColorHasHashtag } from "../../utils/colorUtils";
import { dequal } from "dequal";
import { CardFormV2, CardFormV2Totals } from "../../components/CardForm";

const useStyles = makeStyles({
  eventDetails: {
    "& a": {
      color: COLORS.blue
    }
  }
});

const ANIMATION_DURATION = 500;

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

type SessionCombo = {
  birthYear: string;
  gender: GENDERS;
  session: OpenOrgEventSession;
};

export default function OpenOrgEventRegistrationPage() {
  const params: any = useParams();
  const orgSlug = params.orgSlug;
  const eventSlug = params.eventSlug;
  const location = useLocation();
  const type = location.pathname.split("/")[1] as "tryouts" | "camps";

  if (type !== "tryouts" && type !== "camps") {
    throw new Error("Unsupported type: " + type);
  }

  const { data: org, isFetching: orgIsFetching } = getBifrost().org__server__getOrgBySlugNoAuth.useServer(
    { slug: orgSlug ?? "" },
    {
      enabled: !!orgSlug,
      notifyOnMetaDataChanges: true
    }
  );

  const { data: orgTeamTagsData } = getBifrost().orgTeamTag__client__getTeamTagsForOrg.useClient(
    { orgId: org?.id ?? "" },
    { enabled: !!org }
  );
  const orgTeamTags = orgTeamTagsData ?? [];

  const { data: orgSettings, isFetching: orgSettingsIsFetching } =
    getBifrost().orgSettings__server__getOrgSettingsNoAuth.useServer(
      { orgId: org?.id ?? "" },
      {
        enabled: !!org,
        notifyOnMetaDataChanges: true
      }
    );

  const { data: eventData } = getBifrost().openOrgEvents__client__getOpenOrgEventSubscriptionBySlug.useClientSubscription(
    {
      slug: eventSlug,
      orgId: org?.id ?? ""
    },
    { enabled: !!org?.id }
  );

  const event = eventData?.length ? eventData[0] : undefined;

  const typeStr = type === "tryouts" ? translate({ defaultMessage: "Tryout" }) : translate({ defaultMessage: "Camp" });

  return (
    <View style={{ backgroundColor: COLORS.grey_04, width: "100%", height: "100%" }}>
      <Helmet>
        {org?.logoUri ? <link rel="icon" type="image/jpg" href={org?.logoUri} /> : null}
        <title>
          {org?.shortName ?? ""} {typeStr}
        </title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
      </Helmet>
      {orgSettings ? (
        <ColoredHeader
          bgColor={orgSettings?.primaryColor ? ensureColorHasHashtag(orgSettings.primaryColor) : undefined}
          theme="short"
          title={translate({ defaultMessage: "{typeStr} Registration" }, { typeStr })}
        />
      ) : null}
      <div style={{ maxWidth: 900, margin: "0 auto" }}>
        {(() => {
          console.log("++++++++");
          if (!eventData || (!org && orgIsFetching) || (!orgSettings && orgSettingsIsFetching)) {
            console.log("++++++BAD+++++++");
            return <CenteredLoader style={{ minHeight: 400 }} />;
          }

          if ((!event || !org || !orgSettings) && !!eventData) {
            return (
              <View style={{ padding: 50 }}>
                <StyledText style={{ fontSize: 20, textAlign: "center" }}>
                  {translate({ defaultMessage: "Unable to find event! Please ask your admin for a new link." })}
                </StyledText>
              </View>
            );
          }

          if (moment(event!.signupAvailabilityStartDatetime).isAfter(moment())) {
            return (
              <View style={{ padding: 50 }}>
                <StyledText style={{ fontSize: 20, textAlign: "center" }}>
                  {translate(
                    { defaultMessage: "Registration for {eventName} not yet available. Please check back on {formattedDate}" },
                    {
                      eventName: event!.title,
                      formattedDate: dateFormatters.dddd_mmmm_d_yyyy(event!.signupAvailabilityStartDatetime)
                    }
                  )}
                </StyledText>
              </View>
            );
          }

          if (event!.registrationDeadlineDatetime && moment(event!.registrationDeadlineDatetime).isBefore(moment())) {
            return (
              <View style={{ padding: 50 }}>
                <StyledText style={{ fontSize: 20, textAlign: "center" }}>
                  {translate(
                    { defaultMessage: "Registration for {eventName} has closed!" },
                    {
                      eventName: event!.title
                    }
                  )}
                  {Object.keys(event!.pointOfContact || {}).length
                    ? translate(
                        {
                          defaultMessage: "Please reach out with questions to {contactInfoList}."
                        },
                        {
                          contactInfoList: [
                            event!.pointOfContact?.name,
                            event!.pointOfContact?.phoneNumber,
                            event!.pointOfContact?.email
                          ]
                            .filter(Boolean)
                            .join(" ")
                        }
                      )
                    : null}
                </StyledText>
              </View>
            );
          }

          return (
            <OrgRegistration event={event!} org={org!} typeStr={typeStr} orgSettings={orgSettings!} orgTeamTags={orgTeamTags} />
          );
        })()}
      </div>
    </View>
  );
}

type Section = "session" | "player" | "guardian" | "misc" | "payment";

function OrgRegistration(p: {
  org: Org;
  event: OpenOrgEvent;
  typeStr: string;
  orgSettings: OrgSettings;
  orgTeamTags: OrgTeamTag[];
}) {
  const { org, event, orgSettings } = p;
  const [expandedSections, setExpandedSections] = usePersistentSessionImmutableState(
    {
      session: true,
      player: false,
      guardian: false,
      misc: false,
      payment: false
    },
    "open-org-event-registration-expanded" + event.id
  );

  const orgTeamTag = p.event.orgTeamTagId ? p.orgTeamTags.find(tag => tag.id === p.event.orgTeamTagId) : undefined;

  const [appliedCoupon, setAppliedCoupon] = useState<OrgCoupon | undefined>(undefined);
  const [cardDetails, setCardDetails] = useState<{ cardTokenId?: string }>({});

  const [isProcessing, setIsProcessing] = useState(false);
  const theme = useTheme<Theme>();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [form, setForm] = usePersistentImmutableState<
    Omit<DeepPartial<OpenOrgEventRegistration & { emailConfirmText?: string }>, "id" | "createdAtMS" | "updatedAtMS">
  >(
    {
      openOrgEventId: event.id,
      type: event.type,
      orgId: p.org.id
    },
    "open-org-event-registration-" + event.id
  );

  const formRef = useRef<typeof form>(form);
  const couponRef = useRef<OrgCoupon | undefined>();
  const cardDetailsRef = useRef<typeof cardDetails>(cardDetails);
  useEffect(() => {
    formRef.current = form;
    couponRef.current = appliedCoupon;
    cardDetailsRef.current = cardDetails;
  });

  const [confirmationMsg, setConfirmationMsg] = useState("");
  const [submissionErrorMsg, setSubmissionErrorMsg] = useState("");

  const hasFee = !!(event.feeAmountUSD && event.feeAmountUSD !== "0");

  const renderedSections = _.compact([
    "session",
    "player",
    "guardian",
    !!event.customSections?.length ? "misc" : "",
    hasFee ? "payment" : ""
  ] as const);

  const lastSection = renderedSections.slice(-1).pop()!;

  const scrollToNextFrom = (startSection: Section) => {
    const nextSection = renderedSections[renderedSections.indexOf(startSection) + 1];

    scrollToElement(`#${nextSection}-section`);
  };

  const birthYearAndGenderCombos =
    event.sessions?.reduce((acc, session) => {
      acc = [
        ...acc,
        ...getAllBirthYearAndGenderCombinations(session).map(combo => {
          return { ...combo, session };
        })
      ];
      return acc;
    }, [] as SessionCombo[]) ?? [];

  const topLevelFormRef = useRef<FormRef>(null);

  const pricingInfo: StripePricingInfo = {
    displayString: translate({ defaultMessage: "Registration Payment" }),
    numberCents: (event.feeAmountUSD ? Number(event.feeAmountUSD) : 0) * 100,
    chargeStripeFees: !!p.event.chargeProcessingFees
  };

  function notifyUnexpectedSubmissionError(msg: string, error?: unknown) {
    setSubmissionErrorMsg(msg);
    getBifrost()
      .olliePipe__server__sendOlliePipeEvent.fetchServer({
        type: "metric-open-org-event-registration-error",
        payload: {
          email: formRef.current.guardianContactInfo?.email,
          playerName: formRef.current.playerInfo?.firstName + " " + formRef.current.playerInfo?.lastName,
          guardianName: formRef.current.guardianContactInfo?.firstName + " " + formRef.current.guardianContactInfo?.lastName,
          msg,
          error: error instanceof Error ? { message: error.message, stack: error.stack } : error
        },
        slackImportantErrorsMessage: `
        Unexpected Open Org Event Submission Error!
        OrgId: ${org.name}
        Guardian Details: ${formRef.current.guardianContactInfo?.firstName} ${formRef.current.guardianContactInfo?.lastName} <${formRef.current.guardianContactInfo?.email}>
        Player Details: ${formRef.current.playerInfo?.firstName} ${formRef.current.playerInfo?.lastName}
        Session Info: ${orgTeamTag?.tag} ${formRef.current.tryoutInfo?.sessionSelection?.birthYear} ${formRef.current.tryoutInfo?.sessionSelection?.gender}
      `
      })
      .catch(e => {
        console.error("Ollie pipe failed... We're hosed", e);
      });
  }

  const selectedSession = form.tryoutInfo?.sessionSelection
    ? event.sessions?.find(
        session =>
          session.birthYears.includes(form.tryoutInfo?.sessionSelection?.birthYear ?? "") &&
          form.tryoutInfo?.sessionSelection?.gender &&
          session.genders.includes(form.tryoutInfo?.sessionSelection?.gender)
      )
    : undefined;

  const isSubmittingRef = useRef(false);
  const onSubmit = useCallback(async () => {
    if (isSubmittingRef.current) {
      return;
    }

    setSubmissionErrorMsg("");

    if (!topLevelFormRef.current?.validate()) {
      return notifyUnexpectedSubmissionError(
        translate({ defaultMessage: "Please fill out all registration details. For support contact nate@olliesports.com" })
      );
    }

    try {
      setIsProcessing(true);
      isSubmittingRef.current = true;

      const isFree = compute.payments.getCostAfterDiscount({ appliedCoupon: couponRef.current, pricingInfo }) <= 0;

      if (!isFree && !cardDetailsRef.current.cardTokenId) {
        return notifyUnexpectedSubmissionError(
          translate({ defaultMessage: "Please make sure to enter your credit card information and that everything is valid." })
        );
      }

      const todayPaymentDetails = getTodayPaymentDetails({ pricingInfo, appliedCoupon: couponRef.current });

      const { email, firstName, lastName, phoneNumber } = formRef.current.guardianContactInfo || {};

      if (!email || !firstName || !lastName || !phoneNumber) {
        return notifyUnexpectedSubmissionError(translate({ defaultMessage: "Missing required guardian details!" }));
      }

      const { data: result } = await getBifrost().openOrgEventRegistrations__server__createRegistration.fetchServer({
        paymentInfo: {
          cardTokenId: cardDetailsRef.current.cardTokenId,
          email: email || "",
          name: `${firstName} ${lastName}` || "",
          phoneNumber: phoneNumber || "",
          orgCouponId: couponRef.current?.id
        },
        registration: formRef.current as any,
        locale: getCurrentLocale() ?? "en-us"
      });

      if (result.status === "error") {
        setSubmissionErrorMsg(result.errorMsg);
        return;
      }

      setConfirmationMsg(
        translate(
          {
            defaultMessage:
              "Registration for {playerName} successful! We have sent a confirmation email to {email}. If not found, please check your spam folder."
          },
          {
            playerName: `${formRef.current.playerInfo?.firstName} ${formRef.current.playerInfo?.lastName}`,
            email: formRef.current.guardianContactInfo?.email!
          }
        )
      );

      //Clear the form
      setForm(s => {
        delete s.customSectionResponses;
        delete s.playerInfo;
        delete s.tryoutInfo;
      });

      setExpandedSections({
        session: true,
        player: false,
        guardian: false,
        misc: false,
        payment: false
      });
    } catch (e) {
      console.log(e);
      notifyUnexpectedSubmissionError(
        translate({
          defaultMessage: "There was a problem completing your registration. Please try again or contact nate@olliesports.com"
        })
      );
    } finally {
      isSubmittingRef.current = false;
      setIsProcessing(false);
    }
  }, []);

  const combosSplitByWhetherTheyAreOpen = birthYearAndGenderCombos.reduce(
    (acc, combo) => {
      if (combo.session.sessionTimes.find(t => moment(`${t.date}T${t.endTime}`).add(7, "days").valueOf() > Date.now())) {
        acc.openCombos.push(combo);
      } else {
        acc.closedCombos.push(combo);
      }
      return acc;
    },
    { openCombos: [], closedCombos: [] } as {
      openCombos: SessionCombo[];
      closedCombos: SessionCombo[];
    }
  );

  const hasOpenSessions = !!combosSplitByWhetherTheyAreOpen.openCombos.length;

  return (
    <Form ref={topLevelFormRef}>
      {areFormsValid => {
        const isFree = compute.payments.getCostAfterDiscount({ appliedCoupon: couponRef.current, pricingInfo }) <= 0;
        const isPaymentSectionValid = p.event.feeAmountUSD && !isFree ? !!cardDetails.cardTokenId : true;
        const isEverythingValid = !!(areFormsValid && isPaymentSectionValid && !!formRef.current.tryoutInfo);
        return (
          <View
            style={{
              paddingVertical: 16,
              paddingHorizontal: isMobileDevice ? 16 : 64,
              paddingBottom: 64
            }}
          >
            {isProcessing ? (
              <div
                id="loader-yo"
                style={{
                  position: "fixed",
                  zIndex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  pointerEvents: "none"
                }}
              >
                <ShadowView pointerEvents="auto" style={{ padding: 20, borderRadius: 999, backgroundColor: "white" }}>
                  <CircularProgress />
                </ShadowView>
              </div>
            ) : null}
            <View>
              <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
                {org.logoUri ? (
                  <Image
                    source={{ uri: org.logoUri, width: 40, height: 40 }}
                    resizeMode="contain"
                    style={{ marginRight: isMobileDevice ? 0 : 16, ...{ order: isMobileDevice ? 1 : 0 } }}
                  />
                ) : null}
                <StyledText style={{ fontSize: isMobileDevice ? 24 : 32, flex: 1 }}>{event.title}</StyledText>
              </View>

              {confirmationMsg ? (
                <View>
                  <ShadowView
                    style={{ alignItems: "center", marginTop: isMobileDevice ? 16 : 32, borderRadius: 16, padding: 16 }}
                  >
                    <StyledText style={{ fontSize: 32 }}>{translate.common.Success}</StyledText>
                    <StyledText style={{ marginTop: 8 }}>{confirmationMsg}</StyledText>
                    {form.tryoutInfo?.sessionSelection && selectedSession ? (
                      <View style={{ alignItems: "center", marginTop: 8 }}>
                        <SessionSelect
                          combo={{
                            birthYear: form.tryoutInfo.sessionSelection.birthYear ?? "",
                            gender: form.tryoutInfo.sessionSelection.gender as GENDERS,
                            session: selectedSession
                          }}
                          color={orgSettings.primaryColor ? ensureColorHasHashtag(orgSettings.primaryColor) : COLORS.gold}
                          isMobileDevice={isMobileDevice}
                          isLastOption={false}
                          isSelected={false}
                          onPress={() => {}}
                          viewOnly={true}
                        />
                      </View>
                    ) : null}
                  </ShadowView>
                  <Button
                    style={{
                      backgroundColor: orgSettings.primaryColor ? ensureColorHasHashtag(orgSettings.primaryColor) : COLORS.gold,
                      color: COLORS.white,
                      borderWidth: 0,
                      borderRadius: 100,
                      marginTop: 16
                    }}
                    variant="outlined"
                    onClick={async () => {
                      setConfirmationMsg("");
                    }}
                  >
                    {translate({ defaultMessage: "Register Another Player" })}
                  </Button>
                </View>
              ) : null}
              <View style={{ display: confirmationMsg ? "none" : "flex" }}>
                <EventDetailsSection
                  org={org}
                  event={event}
                  isMobileDevice={isMobileDevice}
                  typeStr={p.typeStr}
                  orgSettings={orgSettings}
                />
                {hasOpenSessions ? (
                  <View>
                    <SessionDetailsSection
                      org={p.org}
                      orgSettings={orgSettings}
                      isMobileDevice={isMobileDevice}
                      combos={combosSplitByWhetherTheyAreOpen}
                      isSessionDetailsExpanded={expandedSections.session}
                      onPress={() => {
                        setExpandedSections({ session: !expandedSections.session });
                      }}
                      selectedSession={
                        form.tryoutInfo?.sessionSelection &&
                        form.tryoutInfo.sessionSelection.birthYear &&
                        form.tryoutInfo.sessionSelection.gender
                          ? {
                              birthYear: form.tryoutInfo.sessionSelection.birthYear,
                              gender: form.tryoutInfo.sessionSelection.gender
                            }
                          : undefined
                      }
                      timezone={event.timezone}
                      onSessionSelect={combo => {
                        setForm({
                          tryoutInfo: {
                            sessionSelection: {
                              birthYear: combo.birthYear,
                              gender: combo.gender,
                              orgTeamTagId: event.orgTeamTagId
                            }
                          }
                        });
                        setExpandedSections({ session: false, player: true });
                        scrollToNextFrom("session");
                      }}
                      isSessionSelected={combo => {
                        return (
                          !!form.tryoutInfo?.sessionSelection &&
                          form.tryoutInfo?.sessionSelection.birthYear === combo.birthYear &&
                          form.tryoutInfo?.sessionSelection.gender === combo.gender
                        );
                      }}
                    />

                    <PlayerDetailsSection
                      org={org}
                      orgSettings={orgSettings}
                      key={!!confirmationMsg + "pd"} //Refresh form for second child
                      isMobileDevice={isMobileDevice}
                      event={event}
                      tryoutInfo={form.tryoutInfo}
                      isPlayerDetailsSectionExpanded={expandedSections.player}
                      onPress={() => {
                        setExpandedSections({ player: !expandedSections.player });
                      }}
                      playerInfo={form.playerInfo ?? {}}
                      onUpdatePlayerInfo={newInfo => {
                        setForm({ playerInfo: newInfo });
                      }}
                      onNextButtonPress={() => {
                        setExpandedSections({ player: false, guardian: true });
                        scrollToNextFrom("player");
                      }}
                    />

                    <GuardianDetailsSection
                      org={p.org}
                      orgSettings={orgSettings}
                      isMobileDevice={isMobileDevice}
                      isEverythingValid={isEverythingValid}
                      isGuardianDetailsSectionExpanded={expandedSections.guardian}
                      isProcessing={isProcessing}
                      onPress={() => {
                        setExpandedSections({ guardian: !expandedSections.guardian });
                      }}
                      guardianContactInfo={form.guardianContactInfo ?? {}}
                      onUpdateGuardianInfo={newInfo => {
                        setForm({ guardianContactInfo: newInfo });
                      }}
                      onSubmitRegistration={() => {
                        onSubmit();
                      }}
                      isLastSection={lastSection === "guardian"}
                      onNextButtonPress={() => {
                        setExpandedSections({
                          guardian: false,
                          misc: event.customSections?.length ? true : false,
                          payment: event.customSections?.length ? false : true
                        });
                        scrollToNextFrom("guardian");
                      }}
                      emailConfirmText={form.emailConfirmText}
                      onUpdateEmailConfirmText={newVal => {
                        setForm({ emailConfirmText: newVal });
                      }}
                    />

                    {event.customSections?.length ? (
                      <MiscSection
                        key={!!confirmationMsg + "misc"} //Refresh form for second child
                        org={p.org}
                        orgSettings={orgSettings}
                        isMiscSectionExpanded={expandedSections.misc}
                        isMobileDevice={isMobileDevice}
                        isEverythingValid={isEverythingValid}
                        isProcessing={isProcessing}
                        customSectionResponses={form.customSectionResponses}
                        onPress={() => {
                          setExpandedSections({ misc: !expandedSections.misc });
                        }}
                        bottomButtonText={
                          lastSection === "misc" ? translate.common.Next : translate({ defaultMessage: "Submit Registration" })
                        }
                        onBottomButtonPress={() => {
                          if (lastSection === "misc") {
                            onSubmit();
                          } else {
                            setExpandedSections({ misc: false, payment: true });
                            scrollToNextFrom("misc");
                          }
                        }}
                        isLastSection={lastSection === "misc"}
                        onUpdateRegistrationAnswers={newInfo => {
                          setForm({ customSectionResponses: newInfo });
                        }}
                        event={event}
                      />
                    ) : null}

                    {hasFee && event.feeAmountUSD ? (
                      <PaymentSection
                        onAppliedCoupon={d => {
                          setAppliedCoupon(d);
                        }}
                        cardDetails={cardDetails}
                        onCardDetails={d => {
                          setCardDetails(d);
                        }}
                        onBottomButtonPress={() => {
                          onSubmit();
                        }}
                        refreshFlipFlop={!!confirmationMsg}
                        feeAmountUSD={event.feeAmountUSD}
                        stepNumber={renderedSections.indexOf("payment") + 1}
                        org={org}
                        orgSettings={orgSettings}
                        isEverythingValid={isEverythingValid}
                        isMobileDevice={isMobileDevice}
                        isPaymentSectionExpanded={expandedSections.payment}
                        onHeaderPress={() => {
                          setExpandedSections({ payment: !expandedSections.payment });
                        }}
                        isPaymentSectionValid={isPaymentSectionValid}
                        initialName={[form.guardianContactInfo?.firstName, form.guardianContactInfo?.lastName]
                          .filter(a => a)
                          .join(" ")}
                        event={p.event}
                        appliedCoupon={appliedCoupon}
                        pricingInfo={pricingInfo}
                        isProcessing={isProcessing}
                      />
                    ) : null}

                    {submissionErrorMsg ? (
                      <InputLabel error={true} style={{ marginTop: 16, marginBottom: 4, fontSize: 24, textAlign: "center" }}>
                        {submissionErrorMsg}
                      </InputLabel>
                    ) : null}

                    {!(hasFee && event.feeAmountUSD) ? (
                      <NextButton
                        disabled={!isEverythingValid || isProcessing}
                        isMobileDevice={isMobileDevice}
                        title={translate({ defaultMessage: "Submit Registration" })}
                        org={p.org}
                        onPress={() => {
                          onSubmit();
                        }}
                        orgSettings={orgSettings}
                      />
                    ) : null}
                  </View>
                ) : (
                  <View style={{ marginTop: 16 }}>
                    <SectionHeader
                      org={p.org}
                      orgSettings={orgSettings}
                      isMobileDevice={isMobileDevice}
                      status={"na"}
                      title={translate({ defaultMessage: "Registration for this event has ended" })}
                    />
                  </View>
                )}
              </View>
            </View>
          </View>
        );
      }}
    </Form>
  );
}

function PaymentSection(p: {
  feeAmountUSD: string;
  org: Org;
  orgSettings: OrgSettings;
  isPaymentSectionExpanded: boolean;
  onHeaderPress: () => void;
  onBottomButtonPress: () => void;
  isPaymentSectionValid: boolean;
  refreshFlipFlop: boolean;
  cardDetails: {
    cardTokenId?: string;
  };
  onCardDetails: (a: { cardTokenId?: string }) => void;
  onAppliedCoupon: (coupon?: OrgCoupon) => void;
  isEverythingValid: boolean;
  stepNumber: number;
  isMobileDevice: boolean;
  initialName: string;
  event: OpenOrgEvent;
  pricingInfo: StripePricingInfo;
  isProcessing: boolean;
  appliedCoupon?: OrgCoupon;
}) {
  const [couponText, setCouponText] = useState("");

  return (
    <ShadowView style={styles.shadowViewContainer} nativeID="payment-section">
      <SectionHeader
        org={p.org}
        orgSettings={p.orgSettings}
        title={`${translate({ defaultMessage: "Payment" })}`}
        stepNumber={p.stepNumber}
        isMobileDevice={p.isMobileDevice}
        isSectionExpanded={p.isPaymentSectionExpanded}
        onPress={() => {
          p.onHeaderPress();
        }}
        status={p.isPaymentSectionValid ? "complete" : "incomplete"}
      />
      <Expando durationMS={ANIMATION_DURATION} isExpanded={p.isPaymentSectionExpanded}>
        <View style={styles.innerContainer}>
          <CardFormV2
            initialName={p.initialName}
            refreshFlipFlop={p.refreshFlipFlop}
            onCardDetailsChange={details => {
              p.onCardDetails({ cardTokenId: details.cardTokenId });
              p.onAppliedCoupon(details.coupon);
            }}
            orgCouponUseType={p.event.type === "tryout" ? OrgCouponUseType.tryouts : OrgCouponUseType.camps}
            orgId={p.org.id}
          />
          <CardFormV2Totals pricingInfo={p.pricingInfo} appliedCoupon={p.appliedCoupon} />

          <NextButton
            disabled={!p.isEverythingValid || p.isProcessing}
            isMobileDevice={p.isMobileDevice}
            onPress={p.onBottomButtonPress}
            title={translate({ defaultMessage: "Submit Registration" })}
            org={p.org}
            orgSettings={p.orgSettings}
          />
        </View>
      </Expando>
    </ShadowView>
  );
}

const PlayerDetailsSection = React.memo(
  (p: {
    isMobileDevice: boolean;
    isPlayerDetailsSectionExpanded: boolean;
    tryoutInfo?: DeepPartial<OpenOrgEventRegistration["tryoutInfo"]>;
    onPress: () => void;
    event: OpenOrgEvent;
    playerInfo: DeepPartial<OpenOrgEventRegistration["playerInfo"]>;
    onUpdatePlayerInfo: (newInfo: DeepPartial<OpenOrgEventRegistration["playerInfo"]>) => void;
    onNextButtonPress: () => void;
    org: Org;
    orgSettings: OrgSettings;
  }) => {
    const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);
    const [imageErrorMsg, setImageErrorMsg] = useState("");
    const formRef = useRef<FormRef>(null);

    const dobToShadow = (val?: string) => (val ? moment(val).format("MMDDYYYY") : "");

    const [dobShadow, setDobShadow] = useState(() => dobToShadow(p.playerInfo.dateOfBirth));

    //Gotta do some weird stuff. DOB can't be fully controlled...
    const shadowHasInitialized = useRef(!!dobShadow);
    useEffect(() => {
      if (!dobShadow && p.playerInfo.dateOfBirth && !shadowHasInitialized.current) {
        setDobShadow(dobToShadow(p.playerInfo.dateOfBirth));
        shadowHasInitialized.current = true;
      }
    }, [dobShadow, p.playerInfo.dateOfBirth]);

    return (
      <Form ref={formRef}>
        {isValid => {
          return (
            <ShadowView style={[styles.shadowViewContainer]} nativeID="player-section">
              <SectionHeader
                org={p.org}
                orgSettings={p.orgSettings}
                title={translate({ defaultMessage: "Player Information" })}
                stepNumber={2}
                isMobileDevice={p.isMobileDevice}
                isSectionExpanded={p.isPlayerDetailsSectionExpanded}
                onPress={p.onPress}
                status={isValid ? "complete" : "incomplete"}
                subTitle={
                  isValid && p.playerInfo.firstName && p.playerInfo.lastName
                    ? `(${p.playerInfo.firstName} ${p.playerInfo.lastName})`
                    : undefined
                }
              />
              <Expando isExpanded={p.isPlayerDetailsSectionExpanded} durationMS={ANIMATION_DURATION}>
                <View style={styles.innerContainer}>
                  <StyledText style={{ fontWeight: "bold" }}>
                    {translate({
                      defaultMessage: "Enter the information for the player who is registering for the event"
                    })}
                  </StyledText>
                  <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                    <View style={{ width: p.isMobileDevice ? "100%" : "50%", paddingRight: p.isMobileDevice ? 0 : 16 }}>
                      <PrettyInput
                        label={translate.common.FirstName}
                        isRequired
                        margin="normal"
                        name="playerFirstName"
                        InputLabelProps={{ shrink: p.playerInfo?.firstName ? true : undefined }}
                        onChange={e => {
                          p.onUpdatePlayerInfo({ ...p.playerInfo, firstName: e.target.value });
                        }}
                        type="text"
                        value={p.playerInfo?.firstName || ""}
                        variant="standard"
                        color={"secondary"}
                      />
                    </View>
                    <View style={{ width: p.isMobileDevice ? "100%" : "50%" }}>
                      <PrettyInput
                        label={translate.common.LastName}
                        isRequired
                        margin="normal"
                        name="playerLastName"
                        InputLabelProps={{ shrink: p.playerInfo?.lastName ? true : undefined }}
                        onChange={e => {
                          p.onUpdatePlayerInfo({ ...p.playerInfo, lastName: e.target.value });
                        }}
                        type="text"
                        value={p.playerInfo?.lastName || ""}
                        variant="standard"
                        color={"secondary"}
                      />
                    </View>
                  </View>
                  <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                    <View
                      style={{
                        width: p.isMobileDevice ? "100%" : "50%",
                        marginTop: p.isMobileDevice ? 16 : 0,
                        paddingRight: p.isMobileDevice ? 0 : 16
                      }}
                    >
                      <PrettyInput
                        id="playerDateOfBirth"
                        validate={curr => {
                          if (!p.playerInfo.dateOfBirth && !dobShadow) {
                            return translate.common.IsRequired;
                          }

                          if (!moment(maskNumericText(dobShadow, "88/88/8888"), "MM/DD/YYYY").isValid()) {
                            return translate({ defaultMessage: "Date must be valid" });
                          }

                          if (!p.playerInfo.dateOfBirth || !dobShadow) {
                            return translate.common.IsRequired;
                          }

                          return "";
                        }}
                        value={maskNumericText(dobShadow, "88/88/8888")}
                        label={translate.common.DateOfBirth + " (MM/DD/YYYY)"}
                        color="secondary"
                        placeholder="MM/DD/YYYY"
                        type="tel"
                        InputLabelProps={{ shrink: p.playerInfo?.dateOfBirth ? true : undefined }}
                        onChange={e => {
                          setDobShadow(e.target.value.replace(/[^\d]/g, ""));

                          p.onUpdatePlayerInfo({
                            ...p.playerInfo,
                            dateOfBirth: e.target.value.match(/\d\d\/\d\d\/\d\d\d\d/)
                              ? moment(e.target.value, "MM/DD/YYYY").isValid()
                                ? moment(e.target.value, "MM/DD/YYYY").format(MOMENT_DATE_FORMAT)
                                : ""
                              : ""
                          });
                        }}
                      />
                      {p.tryoutInfo?.sessionSelection?.birthYear &&
                      p.playerInfo.dateOfBirth &&
                      moment(p.playerInfo.dateOfBirth).year().toString() !== p.tryoutInfo?.sessionSelection.birthYear ? (
                        <StyledText style={{ paddingTop: 8 }}>
                          {translate(
                            {
                              defaultMessage:
                                "Note: You have selected a {sessionBirthYear} session but have entered the player's birth year as {playerBirthYear}"
                            },
                            {
                              playerBirthYear: moment(p.playerInfo.dateOfBirth).year().toString(),
                              sessionBirthYear: p.tryoutInfo?.sessionSelection.birthYear
                            }
                          )}
                        </StyledText>
                      ) : null}
                    </View>
                    <View style={{ width: p.isMobileDevice ? "100%" : "50%", marginTop: p.isMobileDevice ? 16 : 0 }}>
                      <FormControl>
                        <InputLabel color="secondary" id="gender-select-label">
                          {translate.common.Gender}
                        </InputLabel>
                        <PrettySelect
                          value={p.playerInfo.gender || ""}
                          labelId={"gender-select-label"}
                          id="gender-select"
                          isRequired
                          color="secondary"
                          onChange={e => {
                            const val = e.target.value;
                            if (val === GENDERS.boys || val === GENDERS.girls) {
                              p.onUpdatePlayerInfo({ ...p.playerInfo, gender: e.target.value as GENDERS });
                            }
                          }}
                        >
                          <MenuItem value={""}>{translate({ defaultMessage: "Select..." })}</MenuItem>
                          <MenuItem value={GENDERS.boys}>{prettyGender(GENDERS.boys)}</MenuItem>
                          <MenuItem value={GENDERS.girls}>{prettyGender(GENDERS.girls)}</MenuItem>
                        </PrettySelect>
                      </FormControl>
                      {p.tryoutInfo?.sessionSelection?.gender &&
                      p.playerInfo.gender &&
                      p.tryoutInfo.sessionSelection.gender !== p.playerInfo.gender ? (
                        <StyledText style={{ paddingTop: 8 }}>
                          {translate(
                            {
                              defaultMessage:
                                "Note: You have selected a {sessionGender} session but have entered the player's gender as {playerGender}"
                            },
                            {
                              playerGender: prettyGender(p.playerInfo.gender).toLowerCase(),
                              sessionGender: prettyGender(p.tryoutInfo.sessionSelection.gender).toLowerCase()
                            }
                          )}
                        </StyledText>
                      ) : null}
                    </View>
                  </View>
                  <InputLabel error={!!imageErrorMsg} style={{ marginTop: 16, marginBottom: 4 }}>
                    {translate.common.Photo} {p.event.isPhotoRequired ? "" : `(${translate.common.Optional})`}
                  </InputLabel>
                  <ShadowView
                    style={{
                      display: "flex",
                      height: 100,
                      width: 100,
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 4,
                      borderRadius: 8
                    }}
                  >
                    <input
                      id="open-org-event-player-photo-upload"
                      accept={"image/*"}
                      multiple={false}
                      type="file"
                      style={{ display: "none" }}
                      onChange={async event => {
                        var file = event.target.files?.[0];
                        if (file) {
                          setIsUploadingPhoto(true);
                          try {
                            const imgRef = firebase
                              .storage()
                              .ref()
                              .child("open_org_event/" + Date.now() + file.name.replace(/^.*[\\\/]/, ""));

                            await imgRef.put(await downsampleAndCropImage(file, 300));

                            p.onUpdatePlayerInfo({ ...p.playerInfo, profileImageUri: await imgRef.getDownloadURL() });
                            setImageErrorMsg("");
                          } catch (e) {
                            console.error(e);
                            setImageErrorMsg(
                              translate({ defaultMessage: "There was a problem uploading your photo. Please try again" })
                            );
                          }

                          setIsUploadingPhoto(false);
                        }
                      }}
                    />
                    <TouchableOpacity
                      onPress={e => {
                        e.preventDefault();
                        document.getElementById("open-org-event-player-photo-upload")?.click();
                      }}
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      {p.playerInfo.profileImageUri ? (
                        <img
                          alt="profile"
                          style={{ objectFit: "contain", borderRadius: 8, width: "100%", height: "100%" }}
                          src={p.playerInfo.profileImageUri}
                        />
                      ) : isUploadingPhoto ? (
                        <CircularProgress />
                      ) : (
                        <AddCircle
                          style={{
                            margin: 15,
                            backgroundColor: COLORS.white,
                            color: COLORS.grey_07,
                            height: 40,
                            width: 40
                          }}
                        />
                      )}
                    </TouchableOpacity>
                  </ShadowView>
                  {p.playerInfo.profileImageUri ? (
                    <TouchableOpacity
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: 8,
                        paddingLeft: 2,
                        marginTop: 4
                      }}
                      onPress={() => {
                        const elm = document.getElementById("open-org-event-player-photo-upload") as HTMLInputElement;
                        if (elm) {
                          elm.value = "";
                        }
                        p.onUpdatePlayerInfo({ ...p.playerInfo, profileImageUri: undefined });
                      }}
                    >
                      <Delete color="secondary" />
                      <StyledText style={{ marginLeft: 4, color: COLORS.red }}>Delete</StyledText>
                    </TouchableOpacity>
                  ) : null}
                  {imageErrorMsg ? (
                    <InputLabel style={{ fontSize: 12, paddingTop: 6 }} error={true}>
                      {imageErrorMsg}
                    </InputLabel>
                  ) : null}
                  <NextButton
                    org={p.org}
                    orgSettings={p.orgSettings}
                    title={translate.common.Next}
                    disabled={!isValid}
                    onPress={() => {
                      let valid = true;
                      if (p.event.isPhotoRequired && !p.playerInfo.profileImageUri) {
                        setImageErrorMsg(translate({ defaultMessage: "Profile photo is required" }));
                        valid = false;
                      }

                      if (!formRef.current?.validate()) {
                        valid = false;
                      }

                      if (!valid) {
                        return;
                      }

                      setImageErrorMsg("");

                      p.onNextButtonPress();
                    }}
                    isMobileDevice={p.isMobileDevice}
                  />
                </View>
              </Expando>
            </ShadowView>
          );
        }}
      </Form>
    );
  },
  ignoreFunctionsMemoFn
);

function NextButton(p: {
  title: string;
  onPress?: (event: any) => void;
  style?: any;
  disabled?: boolean;
  isMobileDevice: boolean;
  org: Org;
  orgSettings: OrgSettings;
}) {
  return (
    <Button
      disabled={p.disabled}
      style={{
        width: p.isMobileDevice ? "100%" : 200,
        borderRadius: 100,
        marginTop: 16,
        backgroundColor: p.orgSettings.primaryColor ? ensureColorHasHashtag(p.orgSettings.primaryColor) : COLORS.gold,
        opacity: p.disabled ? 0.4 : 1,
        color: COLORS.white
      }}
      onClick={p.onPress}
      size="small"
      variant="contained"
      type="submit"
    >
      {p.title}
    </Button>
  );
}
const GuardianDetailsSection = React.memo(
  (p: {
    isMobileDevice: boolean;
    isGuardianDetailsSectionExpanded: boolean;
    isLastSection: boolean;
    isProcessing: boolean;
    onSubmitRegistration: () => void;
    isEverythingValid: boolean;
    onPress: () => void;
    emailConfirmText?: string;
    onUpdateEmailConfirmText: (newEmailConfirmText: string) => void;
    guardianContactInfo: DeepPartial<OpenOrgEventRegistration["guardianContactInfo"]>;
    onUpdateGuardianInfo: (newInfo: DeepPartial<OpenOrgEventRegistration["guardianContactInfo"]>) => void;
    onNextButtonPress: () => void;
    org: Org;
    orgSettings: OrgSettings;
  }) => {
    const [communicationOptIn, setCommunicationOptIn] = useState(false);

    const optInId = useRef(Math.random().toString()).current;

    const formRef = useRef<FormRef>(null);
    return (
      <Form ref={formRef}>
        {isValid => (
          <ShadowView style={[styles.shadowViewContainer]} nativeID="guardian-section">
            <SectionHeader
              stepNumber={3}
              org={p.org}
              orgSettings={p.orgSettings}
              title={translate({ defaultMessage: "Guardian Information" })}
              isMobileDevice={p.isMobileDevice}
              isSectionExpanded={p.isGuardianDetailsSectionExpanded}
              onPress={p.onPress}
              status={isValid ? "complete" : "incomplete"}
              subTitle={
                isValid && p.guardianContactInfo.firstName && p.guardianContactInfo.lastName
                  ? `(${p.guardianContactInfo.firstName} ${p.guardianContactInfo.lastName})`
                  : ""
              }
            />
            <Expando isExpanded={p.isGuardianDetailsSectionExpanded} durationMS={ANIMATION_DURATION}>
              <View style={styles.innerContainer}>
                <StyledText style={{ fontWeight: "bold" }}>
                  {translate({ defaultMessage: "Enter the guardian/emergency contact information" })}
                </StyledText>
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  <View style={{ width: p.isMobileDevice ? "100%" : "50%", paddingRight: p.isMobileDevice ? 0 : 16 }}>
                    <PrettyInput
                      label={translate.common.FirstName}
                      margin="normal"
                      isRequired
                      name="guardianFirstName"
                      onChange={e => {
                        p.onUpdateGuardianInfo({ ...p.guardianContactInfo, firstName: e.target.value });
                      }}
                      type="text"
                      value={p.guardianContactInfo?.firstName || ""}
                      variant="standard"
                      color={"secondary"}
                    />
                  </View>
                  <View style={{ width: p.isMobileDevice ? "100%" : "50%" }}>
                    <PrettyInput
                      label={translate.common.LastName}
                      margin="normal"
                      isRequired
                      name="guardianLastName"
                      onChange={e => {
                        p.onUpdateGuardianInfo({ ...p.guardianContactInfo, lastName: e.target.value });
                      }}
                      type="text"
                      value={p.guardianContactInfo?.lastName || ""}
                      variant="standard"
                      color={"secondary"}
                    />
                  </View>
                </View>
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  <View style={{ width: p.isMobileDevice ? "100%" : "50%", paddingRight: p.isMobileDevice ? 0 : 16 }}>
                    <PrettyInput
                      label={translate.common.Email}
                      margin="normal"
                      validate={(v: any) => {
                        if (!v) {
                          return translate.common.IsRequired;
                        }

                        if (!v?.match(/.+@.+\..+/)) {
                          return translate({ defaultMessage: "Must be email" });
                        }

                        return "";
                      }}
                      name="guardianEmail"
                      onChange={e => {
                        p.onUpdateGuardianInfo({ ...p.guardianContactInfo, email: e.target.value });
                      }}
                      type="email"
                      value={p.guardianContactInfo?.email || ""}
                      variant="standard"
                      color={"secondary"}
                    />
                  </View>
                  <View style={{ width: p.isMobileDevice ? "100%" : "50%" }}>
                    <PrettyInput
                      label={translate({
                        defaultMessage: "Confirm Email"
                      })}
                      margin="normal"
                      validate={(v: any) => {
                        if (!v) {
                          return translate.common.IsRequired;
                        }

                        if (v !== p.guardianContactInfo.email) {
                          return translate({ defaultMessage: "The emails don't match" });
                        }

                        return "";
                      }}
                      name="guardianConfirmEmail"
                      onChange={e => {
                        p.onUpdateEmailConfirmText(e.target.value);
                      }}
                      type="email"
                      value={p.emailConfirmText || ""}
                      variant="standard"
                      color={"secondary"}
                    />
                  </View>
                </View>
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  <View style={{ width: p.isMobileDevice ? "100%" : "50%", paddingRight: p.isMobileDevice ? 0 : 16 }}>
                    <PrettyInput
                      label={translate.common.PhoneNumber}
                      value={maskNumericText(p.guardianContactInfo?.phoneNumber || "", "888-888-8888")}
                      validate={currVal => {
                        if (!currVal) {
                          return translate.common.IsRequired;
                        }

                        if (!currVal.match(/\d\d\d-\d\d\d-\d\d\d\d/)) {
                          return translate({ defaultMessage: "Must be valid phone number" });
                        }

                        return "";
                      }}
                      margin="normal"
                      name="guardianPhoneNumber"
                      placeholder="888-888-8888"
                      onChange={e => {
                        p.onUpdateGuardianInfo({ ...p.guardianContactInfo, phoneNumber: e.target.value.replace(/[^\d]/g, "") });
                      }}
                      type="tel"
                      variant="standard"
                      color={"secondary"}
                    />
                  </View>
                </View>
                <View>
                  <PrettyCheckbox
                    label={
                      <label>
                        <StyledText style={{ textAlign: "left" }}>
                          {translate(
                            { defaultMessage: "I consent to receive notification emails & texts from {orgName} coaches" },
                            { orgName: p.org.name }
                          )}
                        </StyledText>
                      </label>
                    }
                    checked={communicationOptIn}
                    isRequired
                    onChange={() => setCommunicationOptIn(a => !a)}
                  />
                </View>
                <NextButton
                  org={p.org}
                  orgSettings={p.orgSettings}
                  title={p.isLastSection ? translate({ defaultMessage: "Submit Registration" }) : translate.common.Next}
                  disabled={!isValid || (p.isLastSection && !p.isEverythingValid) || p.isProcessing ? true : false}
                  onPress={() => {
                    if (!formRef.current?.validate()) {
                      return;
                    }

                    if (p.isLastSection) {
                      p.onSubmitRegistration();
                    } else {
                      p.onNextButtonPress();
                    }
                  }}
                  isMobileDevice={p.isMobileDevice}
                />
              </View>
            </Expando>
          </ShadowView>
        )}
      </Form>
    );
  },
  ignoreFunctionsMemoFn
);

const MiscSection = React.memo(
  (p: {
    isMobileDevice: boolean;
    isMiscSectionExpanded: boolean;
    isLastSection: boolean;
    isProcessing: boolean;
    bottomButtonText: string;
    onBottomButtonPress: () => void;
    isEverythingValid: boolean;
    onPress: () => void;
    event: OpenOrgEvent;
    customSectionResponses: DeepPartial<OpenOrgEventRegistration>["customSectionResponses"];
    onUpdateRegistrationAnswers: (newInfo: DeepPartial<OpenOrgEventRegistration>["customSectionResponses"]) => void;
    org: Org;
    orgSettings: OrgSettings;
  }) => {
    const formRef = useRef<FormRef>(null);
    return (
      <Form ref={formRef}>
        {isValid => (
          <ShadowView style={[styles.shadowViewContainer]} nativeID="misc-section">
            <SectionHeader
              org={p.org}
              orgSettings={p.orgSettings}
              title={translate({ defaultMessage: "Miscellaneous" })}
              stepNumber={4}
              isMobileDevice={p.isMobileDevice}
              isSectionExpanded={p.isMiscSectionExpanded}
              onPress={p.onPress}
              status={isValid ? "complete" : "incomplete"}
            />
            <Expando durationMS={ANIMATION_DURATION} isExpanded={p.isMiscSectionExpanded}>
              <View style={styles.innerContainer}>
                {_.orderBy(p.event.customSections ?? [], a => a.sortOrder, "asc").map((customSection, index) => {
                  const responseIndex = p.customSectionResponses?.findIndex(r => r?.customSectionId === customSection.id);
                  const response =
                    responseIndex !== undefined && responseIndex > -1 ? p.customSectionResponses?.[responseIndex] : undefined;
                  return (
                    <CustomSection
                      key={index}
                      isLastOption={!!(p.event.customSections && index === p.event.customSections.length - 1)}
                      customSection={customSection}
                      registrationAnswer={response}
                      onRegistrationAnswerUpdate={newResponse => {
                        if (p.customSectionResponses === undefined) {
                          p.onUpdateRegistrationAnswers(_.compact([newResponse]));
                        } else {
                          const allResponses = p.customSectionResponses.slice();
                          if (responseIndex !== undefined && responseIndex > -1) {
                            allResponses[responseIndex] = newResponse;
                            p.onUpdateRegistrationAnswers(_.compact(allResponses));
                          } else {
                            allResponses.push(newResponse);
                            p.onUpdateRegistrationAnswers(_.compact(allResponses));
                          }
                        }
                      }}
                    />
                  );
                })}
                <NextButton
                  org={p.org}
                  orgSettings={p.orgSettings}
                  title={p.isLastSection ? translate({ defaultMessage: "Submit Registration" }) : translate.common.Next}
                  disabled={!isValid || (p.isLastSection && !p.isEverythingValid) || p.isProcessing ? true : false}
                  onPress={() => {
                    if (!formRef.current?.validate()) {
                      return;
                    }

                    p.onBottomButtonPress();
                  }}
                  isMobileDevice={p.isMobileDevice}
                />
              </View>
            </Expando>
          </ShadowView>
        )}
      </Form>
    );
  },
  ignoreFunctionsMemoFn
);

function CustomSection(p: {
  customSection: OpenOrgEventRegistrationQuestion;
  registrationAnswer?: DeepPartial<OpenOrgEventRegistrationAnswer>;
  onRegistrationAnswerUpdate: (newResponse: OpenOrgEventRegistrationAnswer | undefined) => void;
  isLastOption: boolean;
}) {
  const thisId = useRef(Math.random() + "" + Math.random()).current;

  return (
    <View style={{ marginBottom: p.isLastOption ? 0 : 16 }}>
      <p
        style={{ marginBottom: 12, fontWeight: "bold" }}
        dangerouslySetInnerHTML={{
          __html: escapeHtmlAndWrapLinks(
            `${p.customSection.shortTitle}${"isRequired" in p.customSection && p.customSection.isRequired ? "*" : ""}`
          )
        }}
      />
      {p.customSection.type === OpenOrgEventRegistrationQuestionType.legalDoc ? (
        <View style={styles.customSectionIndentStyle}>
          <StyledText style={{ marginBottom: 16 }}>{p.customSection.documentName}</StyledText>
          {p.customSection.textMD.split(/\n+/).map((str, i) => (
            <p key={i} style={{ marginBottom: 12 }} dangerouslySetInnerHTML={{ __html: escapeHtmlAndWrapLinks(str) }} />
          ))}
          <PrettyCheckbox
            isRequired={p.customSection.isRequired}
            label={
              <label>
                <StyledText style={{ textAlign: "left", textDecoration: "underline" }}>
                  {translate({ defaultMessage: "I Agree" })}
                </StyledText>
              </label>
            }
            onChange={e => {
              if (e.target.checked) {
                p.onRegistrationAnswerUpdate({
                  type: OpenOrgEventRegistrationQuestionType.legalDoc,
                  agreedToAtMS: Date.now(),
                  customSectionId: p.customSection.id,
                  textMD: p.customSection.type === OpenOrgEventRegistrationQuestionType.legalDoc ? p.customSection.textMD : ""
                });
              } else {
                p.onRegistrationAnswerUpdate(undefined);
              }
            }}
            checked={
              !!(
                p.registrationAnswer &&
                p.registrationAnswer.type === OpenOrgEventRegistrationQuestionType.legalDoc &&
                p.registrationAnswer.agreedToAtMS
              )
            }
          />
        </View>
      ) : p.customSection.type === OpenOrgEventRegistrationQuestionType.radio ? (
        <View style={styles.customSectionIndentStyle}>
          <p
            style={{ marginBottom: 16 }}
            dangerouslySetInnerHTML={{ __html: escapeHtmlAndWrapLinks(p.customSection.questionMD) }}
          />
          <PrettyRadioGroup
            isRequired={p.customSection.isRequired}
            value={
              (p.registrationAnswer?.type === OpenOrgEventRegistrationQuestionType.radio && p.registrationAnswer.selection) ?? ""
            }
          >
            {p.customSection.options.map((option, index) => {
              return (
                <View key={index} style={{ flexDirection: "row", alignItems: "center" }}>
                  <Radio
                    id={thisId + index}
                    value={option.valueMD}
                    onClick={() => {
                      p.onRegistrationAnswerUpdate({
                        type: OpenOrgEventRegistrationQuestionType.radio,
                        customSectionId: p.customSection.id,
                        selection: option.valueMD
                      });
                    }}
                  />
                  <label htmlFor={thisId + index}>
                    <StyledText>{option.valueMD}</StyledText>
                  </label>
                </View>
              );
            })}
          </PrettyRadioGroup>
        </View>
      ) : p.customSection.type === OpenOrgEventRegistrationQuestionType.checkbox ? (
        <View style={styles.customSectionIndentStyle}>
          <p
            style={{ marginBottom: 16 }}
            dangerouslySetInnerHTML={{ __html: escapeHtmlAndWrapLinks(p.customSection.questionMD) }}
          />
          <PrettyCheckboxGroup
            options={p.customSection.options.map(o => ({ label: o.valueMD, value: o.valueMD }))}
            isRequired={p.customSection.isRequired}
            value={
              p.registrationAnswer?.type === OpenOrgEventRegistrationQuestionType.checkbox &&
              p.registrationAnswer.selections?.length
                ? _.compact(p.registrationAnswer.selections)
                : undefined
            }
            onChange={newVal => {
              p.onRegistrationAnswerUpdate({
                type: OpenOrgEventRegistrationQuestionType.checkbox,
                customSectionId: p.customSection.id,
                selections: newVal ?? []
              });
            }}
          />
        </View>
      ) : p.customSection.type === OpenOrgEventRegistrationQuestionType.explainer ? (
        <p style={{ marginBottom: 16 }} dangerouslySetInnerHTML={{ __html: escapeHtmlAndWrapLinks(p.customSection.textMD) }} />
      ) : p.customSection.type === OpenOrgEventRegistrationQuestionType.freeResponse ? (
        <View style={styles.customSectionIndentStyle}>
          <p
            style={{ marginBottom: 16 }}
            dangerouslySetInnerHTML={{ __html: escapeHtmlAndWrapLinks(p.customSection.questionMD) }}
          />
          <PrettyInput
            isRequired={"isRequired" in p.customSection && p.customSection.isRequired}
            value={
              p.registrationAnswer && p.registrationAnswer.type === OpenOrgEventRegistrationQuestionType.freeResponse
                ? p.registrationAnswer.response
                : ""
            }
            inputProps={{
              style: { backgroundColor: "rgb(246, 246, 246)", borderRadius: 6, padding: 12 }
            }}
            placeholder={translate({ defaultMessage: "Type response here..." })}
            multiline
            onChange={e => {
              if (!e.target.value) {
                p.onRegistrationAnswerUpdate(undefined);
              } else {
                p.onRegistrationAnswerUpdate({
                  type: OpenOrgEventRegistrationQuestionType.freeResponse,
                  customSectionId: p.customSection.id,
                  response: e.target.value
                });
              }
            }}
          />
        </View>
      ) : null}
    </View>
  );
}

const EventDetailsSection = React.memo(
  (p: { typeStr: string; isMobileDevice: boolean; event: OpenOrgEvent; org: Org; orgSettings: OrgSettings }) => {
    const classes = useStyles({ color: COLORS.blue });
    return (
      <ShadowView style={[styles.shadowViewContainer]}>
        <SectionHeader
          org={p.org}
          orgSettings={p.orgSettings}
          title={translate(
            {
              defaultMessage: "{typeStr} Details"
            },
            { typeStr: p.typeStr }
          )}
          isMobileDevice={p.isMobileDevice}
          isSectionExpanded={true}
          status={"na"}
        />
        <View style={[styles.innerContainer, { flexDirection: p.isMobileDevice ? "column" : "row" }]}>
          <View style={{ flex: 1 }}>
            {p.event.details ? (
              p.event.details
                .split(/\n+/)
                .map((str, i) => (
                  <p
                    key={i}
                    className={classes.eventDetails}
                    style={{ marginBottom: 12, lineHeight: 1.15 }}
                    dangerouslySetInnerHTML={{ __html: escapeHtmlAndWrapLinks(str) }}
                  />
                ))
            ) : (
              <StyledText>
                {translate(
                  {
                    defaultMessage:
                      "{orgName} welcomes you to registrations for {title}. Answer the questions below and let's get you signed up."
                  },
                  { title: p.event.title, orgName: p.org.name }
                )}
              </StyledText>
            )}
            {p.event.registrationDeadlineDatetime ? (
              <View>
                <StyledText style={{ marginTop: 16, fontWeight: "bold" }}>
                  {`${translate({ defaultMessage: "Registration Deadline" })}:`}
                </StyledText>
                <StyledText style={{ paddingTop: 3 }}>
                  {dateFormatters.mmm_d_yyyy(moment(p.event.registrationDeadlineDatetime))}
                </StyledText>
              </View>
            ) : null}
            {Object.values(p.event.pointOfContact || {}).filter(Boolean).length ? (
              <View style={{ marginTop: 16, ...{ gap: 3 } }}>
                <StyledText style={{ fontWeight: "bold" }}>{translate({ defaultMessage: "Primary Contact" }) + ":"}</StyledText>
                {p.event.pointOfContact?.name ? <StyledText>{p.event.pointOfContact?.name}</StyledText> : null}
                {p.event.pointOfContact?.phoneNumber ? (
                  <StyledText>
                    <a style={{ color: COLORS.grey }} href={`tel:${p.event.pointOfContact?.phoneNumber}`}>
                      {p.event.pointOfContact?.phoneNumber}
                    </a>
                  </StyledText>
                ) : null}
                {p.event.pointOfContact?.email ? (
                  <StyledText>
                    <a
                      style={{
                        color: COLORS.grey
                      }}
                      href={`mailto:${p.event.pointOfContact.email}`}
                    >
                      {p.event.pointOfContact.email}
                    </a>
                  </StyledText>
                ) : null}
              </View>
            ) : null}
          </View>
        </View>
      </ShadowView>
    );
  },
  ignoreFunctionsMemoFn
);

const SessionDetailsSection = React.memo(
  (p: {
    isMobileDevice: boolean;
    combos: {
      openCombos: SessionCombo[];
      closedCombos: SessionCombo[];
    };
    isSessionDetailsExpanded: boolean;
    onPress: () => void;
    onSessionSelect: (combo: SessionCombo) => void;
    isSessionSelected: (combo: SessionCombo) => boolean;
    selectedSession?: { birthYear: string; gender: GENDERS };
    timezone: string;
    org: Org;
    orgSettings: OrgSettings;
  }) => {
    return (
      <ShadowView style={styles.shadowViewContainer} nativeID="session-section">
        <SectionHeader
          stepNumber={1}
          org={p.org}
          title={`${translate({ defaultMessage: "Select Session" })}`}
          subTitle={p.selectedSession ? ` (${p.selectedSession.birthYear} ${_.upperFirst(p.selectedSession.gender)})` : ""}
          isMobileDevice={p.isMobileDevice}
          isSectionExpanded={p.isSessionDetailsExpanded}
          onPress={() => {
            p.onPress();
          }}
          orgSettings={p.orgSettings}
          status={p.selectedSession ? "complete" : "incomplete"}
        />
        <Expando isExpanded={p.isSessionDetailsExpanded} durationMS={ANIMATION_DURATION}>
          <View style={{ margin: 20 }}>
            <StyledText style={{ marginBottom: 16, fontWeight: "bold" }}>
              {translate({ defaultMessage: "Please select a session that you want to register for:" })}
            </StyledText>
            {p.combos.openCombos
              .sort((a, b) => {
                return a.birthYear + a.gender < b.birthYear + b.gender
                  ? 1
                  : a.birthYear + a.gender > b.birthYear + b.gender
                  ? -1
                  : 0;
              })
              .map((combo, index) => {
                return (
                  <SessionSelect
                    key={index}
                    combo={combo}
                    color={p.orgSettings.primaryColor ? ensureColorHasHashtag(p.orgSettings.primaryColor) : COLORS.gold}
                    isMobileDevice={p.isMobileDevice}
                    onPress={() => {
                      p.onSessionSelect(combo);
                    }}
                    isLastOption={index === p.combos.openCombos.length - 1}
                    isSelected={p.isSessionSelected(combo)}
                  />
                );
              })}
            {p.combos.closedCombos.length ? (
              <>
                <StyledText style={{ marginBottom: 16, marginTop: 16, fontWeight: "bold" }}>
                  {translate({ defaultMessage: "Unavailable sessions:" })}
                </StyledText>
                {p.combos.closedCombos
                  .sort((a, b) => {
                    return a.birthYear + a.gender < b.birthYear + b.gender
                      ? 1
                      : a.birthYear + a.gender > b.birthYear + b.gender
                      ? -1
                      : 0;
                  })
                  .map((combo, index) => {
                    return (
                      <SessionSelect
                        key={index}
                        combo={combo}
                        color={p.orgSettings.primaryColor ? ensureColorHasHashtag(p.orgSettings.primaryColor) : COLORS.gold}
                        onPress={() => {
                          p.onSessionSelect(combo);
                        }}
                        isLastOption={index === p.combos.closedCombos.length - 1}
                        isSelected={p.isSessionSelected(combo)}
                        isDisabled={true}
                        isMobileDevice={p.isMobileDevice}
                      />
                    );
                  })}
              </>
            ) : null}
          </View>
        </Expando>
      </ShadowView>
    );
  },
  ignoreFunctionsMemoFn
);

function SessionSelect(p: {
  combo: SessionCombo;
  onPress: () => void;
  isLastOption: boolean;
  isSelected: boolean;
  color: string;
  viewOnly?: boolean;
  isDisabled?: boolean;
  isMobileDevice: boolean;
}) {
  return (
    <TouchableOpacity
      style={{
        padding: 8,
        paddingHorizontal: 16,
        borderColor: p.isSelected ? p.color : COLORS.grey_45,
        borderWidth: p.isSelected ? 2 : 1,
        marginBottom: p.isLastOption ? 0 : 8,
        borderRadius: 10,
        backgroundColor: COLORS.white
      }}
      disabled={p.viewOnly || p.isDisabled}
      onPress={p.onPress}
    >
      {p.isDisabled ? (
        <View style={[StyleSheet.absoluteFillObject, { backgroundColor: "rgba(0,0,0, 0.10)", zIndex: 1, borderRadius: 10 }]} />
      ) : null}
      <View style={{ flexDirection: "row", flex: 1, alignItems: "center" }}>
        <View style={{ flex: 1, flexDirection: "column", alignItems: "flex-start", ...{ gap: 4 } }}>
          <StyledText style={{ fontWeight: "bold" }}>{`${p.combo.birthYear} ${_.upperFirst(p.combo.gender)}`}</StyledText>
          {p.combo.session.sessionTimes.map((time, index) => {
            return (
              <StyledText key={index} style={{ fontSize: 12 }}>
                {`${dateFormatters.mmm_d_yyyy_t_tt_a(
                  moment(`${time.date}T${time.startTime}`).toDate()
                )} - ${dateFormatters.t_tt_a(moment(`${time.date}T${time.endTime}`).toDate())}`}
              </StyledText>
            );
          })}
          {p.combo.session.location?.fullAddress || p.combo.session.location?.venue ? (
            <StyledText style={{ fontSize: 12 }}>{`${
              p.combo.session.location.venue
                ? `${p.combo.session.location.venue}${
                    p.combo.session.location.fullAddress ? ` (${p.combo.session.location.fullAddress})` : ""
                  }`
                : p.combo.session.location.fullAddress
            }`}</StyledText>
          ) : null}
          {p.combo.session.locationDetails ? (
            <StyledText style={{ fontSize: 12 }}>{p.combo.session.locationDetails}</StyledText>
          ) : null}
        </View>

        {!p.isDisabled ? (
          <View
            style={{
              marginLeft: 16,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: p.isSelected ? p.color : undefined,
              borderColor: p.isSelected ? p.color : COLORS.grey_45,
              borderWidth: 1,
              borderRadius: 999,
              height: 50,
              width: 50
            }}
          >
            {p.isSelected ? <Check size="38" color={COLORS.white} /> : null}
          </View>
        ) : null}
      </View>
    </TouchableOpacity>
  );
}

export function SectionHeader(p: {
  title: string;
  subTitle?: string;
  isMobileDevice: boolean;
  stepNumber?: number;
  onPress?: () => void;
  isSectionExpanded?: boolean;
  status: "complete" | "incomplete" | "na";
  org: Org;
  orgSettings: OrgSettings;
  disabled?: boolean;
}) {
  const bgColor = p.orgSettings.primaryColor ? ensureColorHasHashtag(p.orgSettings.primaryColor) : COLORS.gold;
  return (
    <TouchableOpacity
      style={{
        backgroundColor: bgColor,
        borderTopRightRadius: 16,
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: p.isSectionExpanded ? 0 : 16,
        borderBottomRightRadius: p.isSectionExpanded ? 0 : 16,
        flexDirection: "row",
        paddingHorizontal: 16,
        alignItems: "center",
        opacity: p.disabled ? 0.75 : 1.0
      }}
      disabled={!p.onPress || p.disabled}
      onPress={p.onPress}
    >
      {p.status !== "na" ? (
        <View
          style={{
            height: 26,
            width: 26,
            backgroundColor: p.status === "complete" ? bgColor : COLORS.white,
            marginRight: 16,
            borderRadius: 100,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {p.status === "complete" ? (
            <Check size="22" color={COLORS.white} />
          ) : p.stepNumber ? (
            <StyledText style={{ color: bgColor, fontWeight: "bold" }}>{p.stepNumber}</StyledText>
          ) : null}
        </View>
      ) : null}
      <View style={{ flexDirection: "row", flex: 1, alignItems: "center" }}>
        <StyledText
          style={{
            color: COLORS.white,
            fontSize: p.isMobileDevice ? 16 : 24,
            marginTop: 16,
            marginBottom: 16
          }}
        >
          {p.title}
        </StyledText>
        {p.subTitle ? (
          <StyledText
            style={{
              color: COLORS.white,
              fontSize: p.isMobileDevice ? 12 : 16,
              marginTop: 16,
              marginBottom: 16,
              marginLeft: 8
            }}
          >
            {p.subTitle}
          </StyledText>
        ) : null}
      </View>
      {p.onPress ? (
        p.isSectionExpanded ? (
          <ArrowDownIcon color={COLORS.white} size={p.isMobileDevice ? "20" : "30"} style={{ margin: 0, padding: 0 }} />
        ) : (
          <ArrowUpIcon color={COLORS.white} size={p.isMobileDevice ? "20" : "30"} style={{ margin: 0, padding: 0 }} />
        )
      ) : null}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  shadowViewContainer: {
    marginTop: 16,
    borderRadius: 16
  },
  innerContainer: {
    padding: 16
  },
  customSectionIndentStyle: {
    // marginLeft: 16
  }
});

function scrollToElement(selector: string) {
  setTimeout(() => {
    const elm = document.querySelector(selector);
    if (!elm) {
      console.error("Unable to find scroll to element!", selector);
    } else {
      elm?.scrollIntoView({ block: "start", behavior: "smooth", inline: "nearest" });
    }
  }, ANIMATION_DURATION + 100);
}

function ignoreFunctionsMemoFn(prevProps: Record<string, any>, nextProps: Record<string, any>) {
  return Object.keys(nextProps)
    .filter(k => typeof nextProps[k] !== "function")
    .every(k => dequal(nextProps[k], prevProps[k]));
}

export function PaymentTotals(p: { pricingInfo: PricingInfo; appliedCoupon?: OrgCoupon }) {
  const todayPaymentDetails = getTodayPaymentDetails(p);
  const isAmountGreaterThan50Cents = todayPaymentDetails.totalAmountDueCents > 50;
  const isFree = todayPaymentDetails.totalAmountDueCents === 0;
  return (
    <Grid container spacing={3} style={{ marginBottom: 16 }}>
      <Grid item xs={6} style={{ color: COLORS.black }}>
        <StyledText>{translate({ defaultMessage: "Subtotal", description: "For payments" }).toUpperCase()}</StyledText>
      </Grid>
      <Grid item xs={6} style={{ color: COLORS.black }}>
        <StyledText>{`$${formatMoneyValue(todayPaymentDetails.subtotalAmountCents)}`}</StyledText>
      </Grid>
      {p.appliedCoupon ? (
        <>
          <Grid item xs={6}>
            <StyledText style={{ color: COLORS.yellow }}>
              {translate({ defaultMessage: "Discount", description: "For payments" }).toUpperCase()}
            </StyledText>
          </Grid>
          <Grid item xs={6}>
            <StyledText style={{ color: COLORS.yellow }}>{`-$${formatMoneyValue(
              todayPaymentDetails.discountAmountCents
            )}`}</StyledText>
          </Grid>
        </>
      ) : null}
      <Grid item xs={6} style={{ color: COLORS.red }}>
        <StyledText>{translate({ defaultMessage: "Platform Fees", description: "For payments" }).toUpperCase()}</StyledText>
      </Grid>
      <Grid item xs={6} style={{ color: COLORS.red }}>
        <StyledText>{`$${formatMoneyValue(todayPaymentDetails.otherFeesAmountDueCents)}`}</StyledText>
      </Grid>
      <Grid item xs={6}>
        <StyledText style={{ color: COLORS.green }}>{translate.common.Total.toUpperCase()}</StyledText>
      </Grid>
      <Grid item xs={6} style={{ color: COLORS.green }}>
        <StyledText>{`$${formatMoneyValue(todayPaymentDetails.totalAmountDueCents)}`}</StyledText>
      </Grid>

      {!isAmountGreaterThan50Cents && !isFree ? (
        <StyledText style={{ marginLeft: 16, color: COLORS.red }}>
          {translate({
            defaultMessage: "We cannot process transactions under $0.50 USD at this time."
          })}
        </StyledText>
      ) : null}
    </Grid>
  );
}
