import { OpenOrgEvent } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { getEndOfFinalOpenOrgEventSession } from "../../utils/open-org-event-utils";

export async function openOrgEvents__server__createOpenOrgEvent(p: {
  event: Omit<OpenOrgEvent, "id" | "slug" | "">;
  selfAccountId: string;
}) {
  const { appOllieFirestoreV2: h } = getServerHelpers();

  if (!p.event.orgId || !(await h.Org.getDoc(p.event.orgId))?.accounts[p.selfAccountId]?.exists) {
    throw new Error("Unauthorized access!");
  }

  const originalSlug = p.event.title
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "-")
    .replace(/--+/g, "-")
    .replace(/-+$/, "");

  let doesSlugExist = true;
  let count = 0;
  let slug = `${originalSlug}`;

  while (doesSlugExist) {
    doesSlugExist = !!(
      await h.OpenOrgEvent.query({
        where: [
          {
            slug: ["==", slug]
          }
        ]
      })
    ).docs.length;
    if (doesSlugExist) {
      slug = `${originalSlug}-${count}`;
    }
  }

  const eventToAdd = {
    ...p.event,
    slug,
    derived: { endOfFinalSession: getEndOfFinalOpenOrgEventSession(p.event.sessions ?? []) }
  };

  await h.OpenOrgEvent.add({
    doc: { ...eventToAdd }
  });
}

openOrgEvents__server__createOpenOrgEvent.auth = (req: any) => {
  return validateTokenAndEnsureSelfAccountIdMatches(req);
};
