import { validateSelfAccountId } from "../../internal-utils/server-auth";
import { getServerHelpers } from "../../helpers";
import { AccountId, OpenOrgEventId, OpenOrgEventRegistration } from "@ollie-sports/models";
import { shouldSendEmail } from "../../utils";
import _ from "lodash";
import { translate } from "@ollie-sports/i18n";

//We generate the export string on the client because it's an uber pain to
//assemble all needful data and we already have the data on the frontend.
export async function openOrgEvents__server__exportPossibleMisregistrations(p: {
  selfAccountId: AccountId;
  openOrgEventIds: OpenOrgEventId[];
}) {
  // SERVER_ONLY_TOGGLE
  const {
    injectedServerLibraries: { sendGrid },
    appOllieFirestoreV2: h,
    getAppPgPool
  } = getServerHelpers();

  const [data, account, accountPrivate] = await Promise.all([
    getAppPgPool().query(
      `select a.registration as registration
from (select r.item                                                       as registration,
             left(r.item -> 'playerInfo' ->> 'dateOfBirth', 4)            as player_birth_year,
             r.item -> 'tryoutInfo' -> 'sessionSelection' ->> 'birthYear' as session_birth_year,
             r.item -> 'tryoutInfo' -> 'sessionSelection' ->> 'gender'    as player_gender,
             r.item -> 'playerInfo' ->> 'gender'                          as session_gender
      from mirror_openorgeventregistration r) a
where (a.player_birth_year <> a.session_birth_year or a.player_gender <> a.session_gender)
  and a.registration ->> 'openOrgEventId' = any ($1::text[]);`,
      [p.openOrgEventIds]
    ),
    h.Account.getDoc(p.selfAccountId),
    h.AccountPrivate.getDoc(p.selfAccountId)
  ]);

  if (!account) {
    throw new Error("Can't find account: " + p.selfAccountId);
  }

  const type = data.rows.length && data.rows[0].registration?.type === "camp" ? "camp" : "tryout";

  const locale = accountPrivate?.communicationLocale ?? "en-us";

  const subject =
    type === "tryout"
      ? translate({ defaultMessage: `Possible Tryout Registration Errors`, serverLocale: locale })
      : translate({ defaultMessage: `Possible Camp Registration Errors`, serverLocale: locale });

  const registrations = data.rows.map(row => {
    return row.registration as OpenOrgEventRegistration;
  }) as OpenOrgEventRegistration[];

  const orgId = registrations.length ? registrations[0].orgId : undefined;

  const orgTeamtags = orgId ? (await h.OrgTeamTag.query({ where: [{ orgId: ["==", orgId] }] })).docs : [];

  let exportString = `${translate.common(locale).FirstName},${translate.common(locale).LastName},${translate({
    defaultMessage: "Category",
    serverLocale: locale
  })},${translate({
    defaultMessage: "Session Birth Year",
    serverLocale: locale
  })},${translate.common(locale).DateOfBirth},${translate({
    defaultMessage: "Session Gender",
    serverLocale: locale
  })},${translate.common(locale).Gender},${translate({
    defaultMessage: "Guardian First Name",
    serverLocale: locale
  })},${translate({
    defaultMessage: "Guardian Last Name",
    serverLocale: locale
  })},${translate({ defaultMessage: "Guardian Email", serverLocale: locale })},${translate({
    defaultMessage: "Guardian Phone Number",
    serverLocale: locale
  })}\n`;

  // 1. First Name
  // 2. Last Name
  // 3. Category
  // 4. Session Birth Year
  // 5. Date of Birth
  // 6. Session Gender
  // 7. Gender
  // 8. Guardian First Name
  // 9. Guardian Last Name
  // 10. Guardian Email
  // 11. Guardian Phone Number

  registrations.forEach(registration => {
    const orgTeamTag = registration.tryoutInfo.sessionSelection.orgTeamTagId
      ? orgTeamtags.find(ott => ott.id === registration.tryoutInfo.sessionSelection.orgTeamTagId)
      : undefined;
    exportString =
      exportString +
      `${registration.playerInfo.firstName},${registration.playerInfo.lastName},${orgTeamTag?.tag ?? ""},${
        registration.tryoutInfo.sessionSelection.birthYear
      },${registration.playerInfo.dateOfBirth},${registration.tryoutInfo.sessionSelection.gender},${
        registration.playerInfo.gender
      },${registration.guardianContactInfo.firstName},${registration.guardianContactInfo.lastName},${
        registration.guardianContactInfo.email
      },${registration.guardianContactInfo.phoneNumber}\n`;
  });

  if (shouldSendEmail(account.email)) {
    await sendGrid.send({
      from: "Ollie Sports <noreply@olliesports>",
      to: account.email,
      subject,
      ipPoolName: "Transactional",
      html: `
    <!DOCTYPE html>
    <html>
    <head>
    <meta charset="utf-8">
    <title>${
      type === "tryout"
        ? translate({ defaultMessage: "Possible Tryout Registration Errors", serverLocale: locale })
        : translate({ defaultMessage: "Possible Camp Registration Errors", serverLocale: locale })
    }</title>
    <meta name="description" content="${subject}">
    <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
    </head>
    <body>
    <p>${
      type === "tryout"
        ? translate({
            defaultMessage: "Attached to this email you will find your exported list of possible tryout registration errors.",
            serverLocale: locale
          })
        : translate({
            defaultMessage: "Attached to this email you will find your exported list of possible camp registration errors.",
            serverLocale: locale
          })
    }.</p>
    </body>
    </html>
    `,
      attachments: [
        {
          content: Buffer.from(exportString).toString("base64"),
          filename: `PossibleErrors.csv`,
          type: "application/csv",
          disposition: "attachment",
          contentId: "possibleErrors"
        }
      ]
    });
  } else {
    console.log(
      `Not sending email because ${account.email} is either not whitelisted or this is prod and the email is an ollie-testing email.`
    );
  }

  // SERVER_ONLY_TOGGLE
}

openOrgEvents__server__exportPossibleMisregistrations.auth = async (req: any) => {
  await validateSelfAccountId(req, req.body.selfAccountId);
};
// i18n certified - complete
