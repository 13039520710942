import { OpenOrgEvent, OrgId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

export async function openOrgEvents__server__checkIfSlugIsUniqueForOrg(p: { orgId: OrgId; slug: string }) {
  const { appOllieFirestoreV2: h } = getServerHelpers();

  const existingOpenOrgEvent = await h.OpenOrgEvent.query({
    where: [{ slug: ["==", p.slug] }, { orgId: ["==", p.orgId] }],
    limit: 1
  });

  if (existingOpenOrgEvent.docs.length) {
    return false;
  }
  return true;
}

openOrgEvents__server__checkIfSlugIsUniqueForOrg.auth = (req: any) => {
  return validateToken(req);
};
