import { validateSelfAccountId } from "../../internal-utils/server-auth";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { GENDERS, OpenOrgEvent, OpenOrgEventRegistration, OpenOrgEventSession, PrettyPlayer, Team } from "@ollie-sports/models";
import {
  getKeyValueStore,
  OrgEmailRequiredPersonalizations,
  getOpenOrgEventRegistrations,
  isProduction,
  sendOrgEmail,
  setKeyValueStore
} from "../../utils";
import _ from "lodash";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import { translate } from "@ollie-sports/i18n";
import moment from "moment";
import { getLocationURL, getVerboseLocationString } from "../../compute/calendarEntry.compute";
import { common__hashObject } from "../common.api";

export async function openOrgEvents__server__sendOpenOrgEventReminders(p: {}) {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool, appOllieFirestoreV2: h, injectedServerLibraries } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();
  const sendGrid = injectedServerLibraries.sendGrid;
  const startTime = moment().add(1, "day").startOf("minute").subtract(1, "minute").valueOf();
  const endTime = moment().add(1, "day").startOf("minute").add(4, "minute").valueOf();

  const queryParams = [startTime, endTime];

  const query = `SELECT * FROM
  (SELECT b.open_org_event,
         Extract(epoch FROM
                       b.earliest_session_time AT TIME ZONE
                       (b.open_org_event ->> 'timezone')::text) * 1000 AS start_time_utc_milliseconds,
         b.session_data from
  (SELECT a.open_org_event,
         (SELECT MIN((session_time ->> 'date')::date + (session_time ->> 'startTime')::time)
          FROM jsonb_array_elements(a.session_data -> 'sessionTimes') AS session_time) AS earliest_session_time,
         a.session_data
  FROM (SELECT item open_org_event, jsonb_array_elements(item -> 'sessions') AS session_data
        FROM mirror_openorgevent) a) b) c
  where c.start_time_utc_milliseconds > $1
    and c.start_time_utc_milliseconds < $2;`;

  const results = (await getAppPgPool().query(query, queryParams)).rows;

  for (let i = 0; i < results.length; i++) {
    const result = results[i];
    const data: {
      openOrgEvent: OpenOrgEvent;
      session: OpenOrgEventSession;
    } = {
      openOrgEvent: result["open_org_event"] as OpenOrgEvent,
      session: result["session_data"] as OpenOrgEventSession
    };
    const hash = common__hashObject({
      obj: {
        sessionTimes: data.session.sessionTimes,
        birthYears: data.session.birthYears,
        genders: data.session.genders,
        openOrgEventId: data.openOrgEvent.id
      }
    });

    const hasSentNotificationForThisSession = !!(await getKeyValueStore(hash));

    if (hasSentNotificationForThisSession) {
      continue;
    }

    const [registrationData, org, orgSettings] = await Promise.all([
      getOpenOrgEventRegistrations({
        orgId: data.openOrgEvent.orgId,
        type: data.openOrgEvent.type,
        selfAccountId: "doesnt matter",
        allValidOpenOrgEventIds: { [data.openOrgEvent.id]: true },
        birthYears: data.session.birthYears.reduce((acc, val) => {
          acc[val] = true;
          return acc;
        }, {} as Record<string, true>),
        orgTeamTagIds: data.openOrgEvent.orgTeamTagId ? { [data.openOrgEvent.orgTeamTagId]: true } : undefined,
        genders: data.session.genders.reduce((acc, val) => {
          acc[val] = true;
          return acc;
        }, {} as Record<string, true>),
        limit: 2000,
        offset: 0,
        openOrgEventIds: { [data.openOrgEvent.id]: true }
      }),
      h.Org.getDoc(data.openOrgEvent.orgId),
      h.OrgSettings.getDoc(data.openOrgEvent.orgId)
    ]);

    // TODO: Maybe translate these at some point but we don't know locales of the registrants since they aren't tied to accounts
    const subject = `${data.openOrgEvent.title} in 24 Hours`;
    const locationString = getVerboseLocationString(data.session.location, "en-us") ?? "";
    const locationURL = getLocationURL(data.session.location) ?? "";

    const personalizations: OrgEmailRequiredPersonalizations[] = registrationData.registrations.map(registration => {
      return {
        email: registration.guardianContactInfo.email,
        name: `${registration.guardianContactInfo.firstName} ${registration.guardianContactInfo.lastName}`,
        subject,
        message: `This is a reminder that <strong>${registration.playerInfo.firstName} ${
          registration.playerInfo.lastName
        }</strong> is registered for <strong>${
          data.openOrgEvent.title
        }</strong>, which is scheduled to begin in <strong>24 hours</strong>.<br><br><strong>${
          registration.tryoutInfo.sessionSelection.birthYear
        } ${_.upperFirst(registration.tryoutInfo.sessionSelection.gender)}</strong><br>${data.session.sessionTimes
          .map(time => {
            return `${moment(`${time.date}T${time.startTime}`).format("dddd, MMMM Do YYYY, h:mm A")} - ${moment(
              `${time.date}T${time.endTime}`
            ).format("hh:mm A")}`;
          })
          .join("<br>")}${
          locationString ? `<br><br>${locationString}${locationURL ? ` <a href="${locationURL}">(map)</a>` : ""}` : ""
        }`
      } as OrgEmailRequiredPersonalizations;
    });
    if (personalizations.length) {
      await sendOrgEmail({
        org: org ?? undefined,
        orgSettings: orgSettings ?? undefined,
        personalizations,
        replyToEmailAddress: orgSettings?.tryoutSettings?.defaultReplyToEmailAddress,
        orgId: data.openOrgEvent.orgId
      });
      olliePipe.emitEvent({
        type: `analytic-sent-open-org-event-reminder`,
        payload: {
          org: org?.name ?? "",
          emailCount: personalizations.length,
          openOrgEventId: data.openOrgEvent,
          session: data.session
        }
      });
    }
    await setKeyValueStore({
      key: hash,
      expirationMS: moment().add(3, "month").valueOf(),
      value: true
    });
  }

  // SERVER_ONLY_TOGGLE
}

openOrgEvents__server__sendOpenOrgEventReminders.auth = async (r: Express.Request) => {
  if (isProduction()) {
    throw new Error("Only should ever be called directly via cron jobs in production!");
  }
};
// i18n certified - complete
