import { OpenOrgEvent } from "@ollie-sports/models";
import moment from "moment";
import { MOMENT_DATE_TIME_FORMAT } from "../../constants";
import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function openOrgEvents__client__openOrgEventsSubscription(p: {
  orgId: string;
  type: OpenOrgEvent["type"];
  showCurrentOnly?: boolean;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftQuerySubToBifrostSub(
    h.OpenOrgEvent.querySubscription({
      where: [{ orgId: ["==", p.orgId] }, { type: ["==", p.type] }],
      limit: 1000
    })
  );
}
