import { OpenOrgEvent } from "@ollie-sports/models";
import moment from "moment";
import { MOMENT_DATE_TIME_FORMAT } from "../../constants";
import { getUniversalHelpers } from "../../helpers";
import {
  firestoreLiftDocSubToBifrostSub,
  firestoreLiftQuerySubToBifrostSub
} from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function openOrgEvents__client__getOpenOrgEventSubscription(p: { openOrgEventId: string }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const sub = h.OpenOrgEvent.docSubscription(p.openOrgEventId);

  return firestoreLiftDocSubToBifrostSub(sub);
}
