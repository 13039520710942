import { OpenOrgEvent } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

export async function openOrgEvents__server__deleteOpenOrgEvent(p: { eventId: string; selfAccountId: string }) {
  const { appOllieFirestoreV2: h } = getServerHelpers();

  const event = await h.OpenOrgEvent.getDoc(p.eventId);

  if (!event || !(await h.Org.getDoc(event.orgId))?.accounts[p.selfAccountId]?.exists) {
    throw new Error("Unauthorized access!");
  }

  await h.OpenOrgEvent.delete({ id: p.eventId });
}

openOrgEvents__server__deleteOpenOrgEvent.auth = (req: any) => {
  return validateTokenAndEnsureSelfAccountIdMatches(req);
};
