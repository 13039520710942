import { COLORS, Team__StaffPresets, compute, getRegistrationStatus, ensureColorHasHashtag } from "@ollie-sports/core";
import React, { useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import { View, Image, TouchableOpacity, Linking } from "react-native-web";
import { useParams } from "react-router-dom";
import { getBifrost } from "../../services/bifrost.service";
import { ColoredHeader } from "../../components/ColoredHeader";
import { dateFormatters, getCurrentLocale, translate } from "@ollie-sports/i18n";
import { CenteredLoader } from "../../components/CenteredLoader";
import { StyledText } from "../../components/StyledText";
import { AccountId, CODE_TYPES, OpenOrgEventRegistration, Org, OrgSettings, Team } from "@ollie-sports/models";
import { Button, CircularProgress, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { ShadowView } from "../../components/ShadowView";
import { config } from "../../config";
import { generateUniversalLinkBase, generateUniversalLinkForCode } from "../../utils/deferred-link";
import _ from "lodash";
import moment from "moment";
import { escapeHtmlAndWrapLinks } from "../../utils/parseText";
import { useSynchronousState } from "../../hooks/useSynchronousState";

export default function OpenOrgEventRespondToOfferPage() {
  const params: any = useParams();
  const teamId = params.teamId;
  const openOrgEventRegistrationId = params.openOrgEventRegistrationId;

  const { data: team, isFetching: teamIsFetching } = getBifrost().teams__server__getTeamNoAuth.useServer(
    { teamId: teamId ?? "" },
    {
      enabled: !!teamId,
      notifyOnMetaDataChanges: true,
      useCacheOnlyWithinMS: Infinity
    }
  );

  const { data: org, isFetching: orgIsFetching } = getBifrost().org__server__getOrgNoAuth.useServer(
    { orgId: team?.orgId ?? "" },
    {
      enabled: !!team,
      notifyOnMetaDataChanges: true,
      useCacheOnlyWithinMS: Infinity
    }
  );

  const { data: orgSettings, isFetching: orgSettingsIsFetching } =
    getBifrost().orgSettings__server__getOrgSettingsNoAuth.useServer(
      { orgId: team?.orgId ?? "" },
      {
        enabled: !!team,
        notifyOnMetaDataChanges: true,
        useCacheOnlyWithinMS: Infinity
      }
    );

  const { data: registration, isFetching: registrationIsFetching } =
    getBifrost().openOrgEventRegistrations__client__getRegistrationSubscription.useClientSubscription(
      { openOrgEventRegistrationId },
      {
        enabled: !!openOrgEventRegistrationId,
        notifyOnMetaDataChanges: true,
        useCacheOnlyWithinMS: Infinity
      }
    );

  return (
    <View style={{ backgroundColor: COLORS.grey_04, width: "100%", height: "100%" }}>
      <Helmet>
        {org?.logoUri || team?.logoUri ? <link rel="icon" type="image/jpg" href={org?.logoUri ?? team?.logoUri} /> : null}
        <title>{team?.name}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
      </Helmet>
      {orgSettings ? (
        <ColoredHeader
          bgColor={orgSettings?.primaryColor ? ensureColorHasHashtag(orgSettings.primaryColor) : undefined}
          theme="short"
          title={translate({ defaultMessage: "Tryout Offer" })}
        />
      ) : null}
      <div style={{ maxWidth: 900, margin: "0 auto" }}>
        {(() => {
          if (
            (!registration && registrationIsFetching) ||
            (!team && teamIsFetching) ||
            (!org && orgIsFetching) ||
            (!orgSettings && orgSettingsIsFetching)
          ) {
            return <CenteredLoader style={{ minHeight: 400 }} />;
          }

          if (!registration || !team || !org || !orgSettings) {
            return (
              <View style={{ padding: 50 }}>
                <StyledText style={{ fontSize: 20, textAlign: "center" }}>
                  {translate({ defaultMessage: "Unable to find the offer! Please reach out to the coach for assistance." })}
                </StyledText>
              </View>
            );
          }

          return (
            <OpenOrgEventRespondToOfferPageInner
              registration={registration!}
              org={org!}
              team={team!}
              orgSettings={orgSettings!}
            />
          );
        })()}
      </div>
    </View>
  );
}

function OpenOrgEventRespondToOfferPageInner(p: {
  team: Team;
  org: Org;
  orgSettings: OrgSettings;
  registration: OpenOrgEventRegistration;
}) {
  const { org, orgSettings, team, registration } = p;
  const [isProcessing, setIsProcessing] = useSynchronousState(false);
  const theme = useTheme<Theme>();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  let topStaffId: AccountId | undefined = undefined;
  const locale = getCurrentLocale();
  const [teamInviteCode, setTeamInviteCode] = useState<string | undefined>();
  const [errorMsg, setErrorMsg] = useState("");

  const inviteActionsForThisTeam = _.orderBy((p.registration.tryoutInfo.actions ?? []).filter(a => a.teamId === p.team.id));
  const status = getRegistrationStatus(inviteActionsForThisTeam, p.team.id);
  const outstandingInvitation = _.orderBy(inviteActionsForThisTeam, a => a.createdAtMS, "desc").find(
    a => a.type === "invite" && a.teamId === p.team.id
  );

  const codeString = teamInviteCode
    ? String(teamInviteCode)
        .replace(/(\d\d\d)/g, "-$1")
        .replace("-", "")
    : "";

  const topStaff = team ? compute.team.extractHighestRankingStaff(team) : null;
  if (topStaff) {
    topStaffId = topStaff.accountId;
  }

  useEffect(() => {
    (async () => {
      if (status === "invite-accepted" || status === "manual-player-accept") {
        const { data: teamCode } = await getBifrost().teams__server__getInviteCodeNoAuth.fetchServer({
          teamId: p.team.id,
          type: CODE_TYPES.joinTeam
        });
        setTeamInviteCode(teamCode.code);
      }
    })();
  }, [status]);

  const universalUrl = teamInviteCode
    ? generateUniversalLinkForCode({ code: teamInviteCode })
    : generateUniversalLinkBase() + "/download";

  const { data: staffAccount } = getBifrost().account__server__getAccountNoAuth.useServer(
    {
      accountId: topStaffId ?? ""
    },
    {
      enabled: !!topStaffId,
      notifyOnMetaDataChanges: true,
      useCacheOnlyWithinMS: Infinity
    }
  );

  const staffInfo =
    staffAccount && topStaff ? (
      <View style={{ marginTop: 16 }}>
        <StyledText style={{ fontWeight: "bold" }}>{translate({ defaultMessage: "Contact Information" }) + ":"}</StyledText>
        <StyledText>{Team__StaffPresets(locale)[topStaff.role].staffTitle}</StyledText>
        <StyledText>{staffAccount.firstName + " " + staffAccount.lastName}</StyledText>
        <StyledText>{staffAccount.email}</StyledText>
        {staffAccount.phoneNumber ? <StyledText>{staffAccount.phoneNumber}</StyledText> : null}
      </View>
    ) : null;
  return (
    <View
      style={{
        paddingVertical: 16,
        paddingHorizontal: isMobileDevice ? 16 : 64,
        paddingBottom: 64
      }}
    >
      {isProcessing.current ? (
        <div
          id="loader-yo"
          style={{
            position: "fixed",
            zIndex: 1,
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            pointerEvents: "none"
          }}
        >
          <ShadowView pointerEvents="auto" style={{ padding: 20, borderRadius: 999, backgroundColor: "white" }}>
            <CircularProgress />
          </ShadowView>
        </div>
      ) : null}
      <View>
        <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
          {org.logoUri ? (
            <Image
              source={{ uri: org.logoUri, width: 75, height: 40 }}
              resizeMode="contain"
              style={{ marginRight: isMobileDevice ? 0 : 16, ...{ order: isMobileDevice ? 1 : 0 } }}
            />
          ) : null}
          <StyledText style={{ fontSize: 32, flex: 1 }}>{team.name}</StyledText>
        </View>
        <ShadowView style={{ marginTop: isMobileDevice ? 16 : 32, borderRadius: 16, padding: 16 }}>
          {status === "invite-pending" ? (
            <View>
              <StyledText style={{ marginBottom: 16 }}>{`${registration.playerInfo.firstName},`}</StyledText>

              <StyledText>
                {translate(
                  { defaultMessage: "Congratulations on receiving an offer to play for {teamName}!" },
                  { teamName: team.name }
                )}
              </StyledText>

              {outstandingInvitation && outstandingInvitation.expiresAtMS ? (
                <View>
                  <StyledText style={{ fontWeight: "bold", marginTop: 16 }}>
                    {translate({
                      defaultMessage: "Offer Expiration",
                      description: "This is a title for when the offer will expire"
                    }) + ":"}
                  </StyledText>
                  <StyledText>{dateFormatters.mmm_d_t_tt_a(moment(outstandingInvitation.expiresAtMS).toDate())}</StyledText>
                </View>
              ) : null}

              {staffInfo}

              <StyledText style={{ fontWeight: "bold", marginTop: 16, marginBottom: 8 }}>
                {translate({ defaultMessage: "Do you accept or decline this offer?" })}
              </StyledText>
              <View style={{ flexDirection: "row" }}>
                <ActionButton
                  color={COLORS.green}
                  isMobileDevice={isMobileDevice}
                  isProcessing={isProcessing.current}
                  onPress={async () => {
                    if (isProcessing.current) {
                      return;
                    }
                    setIsProcessing(true);
                    setErrorMsg("");
                    try {
                      await getBifrost().openOrgEventRegistrations__server__respondToOffer.fetchServer({
                        openOrgEventRegistrationId: registration.id,
                        orgId: org.id,
                        response: "accept",
                        teamId: team.id
                      });
                    } catch (e) {
                      await getBifrost().olliePipe__server__sendOlliePipeEvent.fetchServer({
                        type: "error-tryout-accept-failure",
                        payload: {
                          error: "message" in (e as any) ? (e as any).message : "",
                          playerInfo: registration.playerInfo,
                          guardianContactInfo: registration.guardianContactInfo,
                          invitingAccountId: outstandingInvitation?.triggeredByAccountId,
                          teamName: p.team.name
                        },
                        slackImportantErrorsMessage: `
                        Tryout Acceptance Failure!
                        Team Name: ${team.name}
                        Team ID: ${team.id}
                        Guardian Details: ${registration.guardianContactInfo?.firstName} ${registration.guardianContactInfo?.lastName} <${registration.guardianContactInfo?.email}>
                        Player Details: ${registration.playerInfo?.firstName} ${registration.playerInfo?.lastName}
                        Inviting Account ID: ${outstandingInvitation?.triggeredByAccountId}
                      `
                      });
                      setErrorMsg(translate.common.SomethingWentWrong);
                      // TODO: ERROR HANDLING
                    }
                    setIsProcessing(false);
                  }}
                  title={translate.common.Accept}
                  marginRight={16}
                />
                <ActionButton
                  color={COLORS.red}
                  isMobileDevice={isMobileDevice}
                  isProcessing={isProcessing.current}
                  onPress={async () => {
                    if (isProcessing.current) {
                      return;
                    }
                    setIsProcessing(true);
                    setErrorMsg("");
                    try {
                      await getBifrost().openOrgEventRegistrations__server__respondToOffer.fetchServer({
                        openOrgEventRegistrationId: registration.id,
                        orgId: org.id,
                        response: "reject",
                        teamId: team.id
                      });
                    } catch (e) {
                      await getBifrost().olliePipe__server__sendOlliePipeEvent.fetchServer({
                        type: "error-tryout-reject-failure",
                        payload: {
                          error: "message" in (e as any) ? (e as any).message : "",
                          playerInfo: registration.playerInfo,
                          guardianContactInfo: registration.guardianContactInfo,
                          invitingAccountId: outstandingInvitation?.triggeredByAccountId,
                          teamName: p.team.name
                        },
                        slackImportantErrorsMessage: `
                        Tryout Rejection Failure!
                        Team Name: ${team.name}
                        Team ID: ${team.id}
                        Guardian Details: ${registration.guardianContactInfo?.firstName} ${registration.guardianContactInfo?.lastName} <${registration.guardianContactInfo?.email}>
                        Player Details: ${registration.playerInfo?.firstName} ${registration.playerInfo?.lastName}
                        Inviting Account ID: ${outstandingInvitation?.triggeredByAccountId}
                      `
                      });
                      setErrorMsg(translate.common.SomethingWentWrong);
                      // TODO: ERROR HANDLING
                    }
                    setIsProcessing(false);
                  }}
                  title={translate.common.Decline}
                />
              </View>
              {errorMsg ? <StyledText style={{ marginTop: 16, fontSize: 24, color: COLORS.red }}>{errorMsg}</StyledText> : null}
            </View>
          ) : status === "invite-accepted" || status === "manual-player-accept" ? (
            <View style={{ flex: 1 }}>
              <StyledText style={{ fontWeight: "bold", fontSize: 32 }}>{translate.common.Accepted}</StyledText>
              <StyledText style={{ marginTop: 16 }}>
                {status === "invite-accepted"
                  ? translate({
                      defaultMessage:
                        "Thank you for your response. We have let the coach know of your decision. We have also sent you an email with the following instructions."
                    })
                  : translate({ defaultMessage: "The coach has marked you as accepted." })}
              </StyledText>
              <StyledText
                style={{
                  marginTop: 16,
                  fontWeight: "bold"
                }}
              >
                {translate({
                  defaultMessage:
                    "Now it's time to join the team on the Ollie app to access the team schedule and communication.",
                  description: "Header for a section that will tell the user the next steps"
                })}
              </StyledText>
              <StyledText style={{ marginTop: 8 }}>
                {translate({
                  defaultMessage: "Use the following link (on your phone) and code to download the Ollie app and join the team!"
                })}
              </StyledText>
              <View style={{ flexDirection: isMobileDevice ? "column" : "row", marginTop: 8 }}>
                <StyledText style={{ fontWeight: "bold", marginRight: 2 }}>{translate.common.Link + ":"}</StyledText>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL(universalUrl);
                  }}
                >
                  <p dangerouslySetInnerHTML={{ __html: escapeHtmlAndWrapLinks(universalUrl) }} />
                  <StyledText style={{ color: COLORS.blue, textDecorationLine: "underline" }}></StyledText>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    navigator.clipboard.writeText(codeString.replace(/-/g, ""));
                  }}
                  style={{ marginLeft: 2 }}
                >
                  <StyledText
                    style={{ color: COLORS.blue, textDecorationLine: "underline" }}
                  >{`(${translate.common.Copy})`}</StyledText>
                </TouchableOpacity>
              </View>
              <View style={{ flexDirection: isMobileDevice ? "column" : "row", marginTop: 8 }}>
                <StyledText style={{ fontWeight: "bold", marginRight: 2 }}>{translate.common.Code + ":"}</StyledText>
                <StyledText>{codeString}</StyledText>
                <TouchableOpacity
                  onPress={() => {
                    navigator.clipboard.writeText(codeString.replace(/-/g, ""));
                  }}
                  style={{ marginLeft: 2 }}
                >
                  <StyledText
                    style={{ color: COLORS.blue, textDecorationLine: "underline" }}
                  >{`(${translate.common.Copy})`}</StyledText>
                </TouchableOpacity>
              </View>
              <StyledText style={{ fontWeight: "bold", marginTop: 16 }}>
                {translate({ defaultMessage: "Already have the app?" })}
              </StyledText>
              <StyledText style={{}}>
                {translate({
                  defaultMessage:
                    "Just click the link on your phone or find the Join Team button on the Teams tab and enter the code."
                })}
              </StyledText>
            </View>
          ) : status === "invite-rejected" ? (
            <View style={{ flex: 1 }}>
              <StyledText style={{ fontWeight: "bold", fontSize: 32 }}>{translate.common.Declined}</StyledText>
              <StyledText style={{ marginTop: 16 }}>
                {translate({
                  defaultMessage: "Thank you for your response. We have let the coach know of your decision."
                })}
                {staffInfo}
              </StyledText>
            </View>
          ) : status === "invite-expired" ? (
            <View style={{ flex: 1 }}>
              <StyledText style={{ fontWeight: "bold", fontSize: 32 }}>{translate.common.Expired}</StyledText>
              <StyledText style={{ marginTop: 16 }}>
                {translate({
                  defaultMessage: "This offer has expired."
                })}
                {staffInfo}
              </StyledText>
            </View>
          ) : status === "alternate" ? (
            <View style={{ flex: 1 }}>
              <StyledText style={{ fontWeight: "bold", fontSize: 32 }}>{translate.common.Alternate}</StyledText>
              <StyledText style={{ marginTop: 16 }}>
                {translate({
                  defaultMessage: "You are an alternate. If a spot opens up, the coach will send you an official offer."
                })}
                {staffInfo}
              </StyledText>
            </View>
          ) : status === "invite-canceled" ? (
            <View style={{ flex: 1 }}>
              <StyledText style={{ fontWeight: "bold", fontSize: 32 }}>{translate.common.Canceled}</StyledText>
              <StyledText style={{ marginTop: 16 }}>
                {translate({
                  defaultMessage: "This offer has been canceled."
                })}
                {staffInfo}
              </StyledText>
            </View>
          ) : (
            <View style={{ flex: 1 }}>
              <StyledText>
                {translate({
                  defaultMessage: "We can't find record of your tryout invitation. Please contact us at support@olliesports.com"
                })}
              </StyledText>
            </View>
          )}
        </ShadowView>
      </View>
    </View>
  );
}

function ActionButton(p: {
  isProcessing: boolean;
  isMobileDevice: boolean;
  color: COLORS;
  onPress: () => Promise<void>;
  title: string;
  marginRight?: number;
}) {
  return (
    <Button
      disabled={p.isProcessing}
      style={{
        width: 80,
        borderRadius: 100,
        marginTop: 16,
        backgroundColor: p.color,
        opacity: p.isProcessing ? 0.4 : 1,
        color: COLORS.white,
        marginRight: p.marginRight
      }}
      onClick={p.onPress}
      size="small"
      variant="contained"
      type="submit"
    >
      {p.title}
    </Button>
  );
}
